import React, { useContext, useEffect, useState } from 'react'
import { Grid } from '../components/common/grid/Grid'
import { GridItem } from '../components/common/grid/GridItem'
import { useForm } from 'react-hook-form'
import { Demand, DemandStatus } from '../api/schemas/schema'
import { useTranslation } from 'react-i18next'
import { Page } from './Page'
import { fakeIdGenerator } from '../helpers/demandHelper'
import { useParams } from 'react-router-dom'
import { DataContext } from '../App'
import { IBreadcrumb } from '../interfaces/IBreadcrumb'
import { BasicBreadcrumb } from '../components/common/breadcrumb/BasicBreadcrumb'
import { SelectedQuestion } from '../components/question/SelectedQuestion'
import SelectedDemand from './Demand/SelectedDemand'

export const DemandQuestion = () => {
   const { id } = useParams()
   const dataContext = useContext(DataContext)
   const { demands } = dataContext.state
   const { t } = useTranslation()
   const demandForm = useForm<Demand>()
   const { watch, reset } = demandForm

   useEffect(() => {
      reset(demands.find((x) => x.id === id))
      setBread()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id])
   const [breadcrumbItems, setBreadcrumbItems] = useState<IBreadcrumb[]>()
   const setBread = () => {
      let breadcrumbs: IBreadcrumb[] = []

      const updateBreadcrumbs = (id: string) => {
         const parent = demands.find((x) => x.id === id)
         if (parent) {
            breadcrumbs.push({
               path: `/demands/${parent.id}`,
               text: `${t('Demand')} - ${fakeIdGenerator(
                  parent,
                  t,
                  demands.find((x) => x.id === parent.parentId)
               )}`,
            })
            updateBreadcrumbs(parent.parentId)
         }
      }

      let currentId = id
      updateBreadcrumbs(currentId)

      breadcrumbs.push({ text: t('DemandQuestionList'), path: '/demands' })
      breadcrumbs.reverse()
      setBreadcrumbItems(breadcrumbs)
   }
   useEffect(() => {
      if (demands.length > 0 && id) {
         reset(demands.find((x) => x.id === id))
         setBread()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id, demands])
   const isDisabled = watch() && watch('status') === DemandStatus.Archived
   return (
      <>
         <Page header={<BasicBreadcrumb breadcrumbItems={breadcrumbItems} />}>
            <Grid>
               <SelectedDemand demandForm={demandForm} isDisabled={isDisabled} />
               <GridItem size="12/12">
                  <SelectedQuestion demand={watch()} />
               </GridItem>
            </Grid>
         </Page>
      </>
   )
}
