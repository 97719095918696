import { Body1, Body1Strong, Caption1 } from '@fluentui/react-components'
import React from 'react'

type TextProps = {
   as?: 'text' | 'strong' | 'caption'
   children: string | string[] | number
   className?: string
   onClick?: () => void
}
export const Text = ({ as, children, className, onClick }: TextProps) => {
   switch (as) {
      case 'strong':
         return (
            <Body1Strong onClick={onClick} className={className ?? ''} as={'strong'}>
               {children}
            </Body1Strong>
         )
      case 'caption':
         return (
            <Caption1 onClick={onClick} className={className ?? ''} as={'p'}>
               {children}
            </Caption1>
         )
      case 'text':
      default:
         return (
            <Body1 onClick={onClick} className={className ?? ''} as={'p'}>
               {children}
            </Body1>
         )
   }
}
