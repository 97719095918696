import { Badge, makeStyles } from '@fluentui/react-components'

type ToggleButtonProps = {
   onClick: () => void
   selected: boolean
   children: string
}
const useStyles = makeStyles({
   pointer: { cursor: 'pointer' },
})
export const ToggleButton = ({ onClick, selected, children }: ToggleButtonProps) => {
   const classes = useStyles()
   return (
      <Badge
         role={selected ? 'header' : 'option'}
         className={classes.pointer}
         size="large"
         shape="rounded"
         appearance={selected ? 'filled' : 'outline'}
         color="brand"
         onClick={onClick}
      >
         {children}
      </Badge>
   )
}
