export const sv = {
   AppHeader: '365 Back office',

   Title: 'Titel',
   Status: 'Status',
   ID: 'ID',

   SfsNo: 'SFS Nr',
   Department: 'Departement/Myndighet',
   IssuedAt: 'Utfärdad',
   ModifiedAt: 'Ändrad',
   ModificationRegister: 'Ändringsregister',
   Source: 'Källa',
   LawsToValidate: 'Bearbeta lagar och välj om de ska vara ett krav i 365 Robust eller ej.',
   Content: 'Innehåll',

   ShowLawsWithStatusNo: 'Visa lagar med status "Nej"',
   Number: 'Nummer',
   Subject: 'Ämne',
   LeadershipPerspective: 'Ledningsperspektiv',
   NumberOfLaws: 'Antal lagar',
   Modified: 'Senast ändrad',
   ModifiedBy: 'Senast ändrad av',
   NewDemand: 'Nytt krav',
   ChoosenDemands: 'Utvalda krav',
   ChoosenDemandsInfo: 'Detta är de krav som är valda och skapta. Det är dessa som kommer speglas i 365 Robust. Under fliken "Lagar" kan du ta in fler lagar',
   WebLink: 'Webblänk',
   Description: 'Beskrivning',
   ChooseParagraphsInfo: 'Välj de paragrafer som ska bli krav',
   ClearDemand: 'Rensa pågående kravhantering',
   ClearDemandText: 'Är du säker på att du vill rensa den pågående kravhanteringen? Detta raderar data och återställer statusen. Det går ej att ångra',
   LawWithStatusNoCantBeHandled: 'Lag med status "nej" kan ej bearbetas.',
   ChangeStatusToHandleLaw: 'För att hantera denna lag behöver statusen ändras till "ja" eller "kanske"',
   QuestionWillBeDeletedOnContinue:
      'Denna fråga kommer att raderas om du klickar på fortsätt. Vänligen spara frågan innan du byter fråga ifall att du vill spara den.',

   SignIn: 'Logga in',
   SignOut: 'Logga ut',
   WelcomeTo: 'Välkommen till',
   SystemSettings: 'Systeminställningar',

   // Nav
   Dashboard: 'Dashboard',
   Laws: 'Lagar',
   InitialHandling: 'Grovhantering',
   DemandQuestionList: 'Krav- och frågelista',

   // Status
   Yes: 'Ja',
   No: 'Nej',
   Maybe: 'Kanske',
   Demand: 'Krav',
   OngoingDemand: 'Pågående krav',
   Updated: 'Uppdaterad',
   Revoked: 'Upphävd',
   New: 'Ny',
   All: 'Alla',
   Empty: '(Tom)',
   Definite: 'Fastställd',
   Ongoing: 'Pågående',
   Reviewing: 'Revideras',
   Resting: 'Vilande',
   Archived: 'Arkiverad',
   SavedButNotSure: '',
   Archive: 'Arkivera',

   // Demand
   SubType: 'Underkravtyp',
   EUDirective: 'EU förordning',
   Law: 'Lag',
   Paragraph: 'Paragraf',
   ISOStandard: 'ISO standard',
   Ordinance: 'Förordning', // Förordning
   Regulation: 'Föreskrift', // Föreskrift
   GeneralAdvice: 'Allmänt råd',
   Guidance: 'Vägledning',
   IndustryDemand: 'Branschkrav',
   InternalDemand: 'Internt krav',
   DemandType: 'Kravtyp',
   Origin: 'Ursprung',
   Sweden: 'Sverige',
   DemandLevel: 'Kravnivå',
   PublicAuthorityDemand: 'Myndighetskrav',
   InsuranceDemand: 'Försäkringskrav',
   OtherDemand: 'Annat krav',
   DemandArea: 'Kravområde',
   Economy: 'Ekonomi',
   Sustainability: 'Hållbarhet',
   Quality: 'Kvalitet',
   Environment: 'Miljö',
   Staff: 'Personal',
   Security: 'Säkerhet',
   StatusImport: 'Importstatus',
   Question: 'Fråga',
   DemandText: 'Kravtext',
   AnswerType: 'Svarsform',
   DemandSubType: 'Underkravtyp',
   DemandSource: 'Kravkälla',
   AllDemands: 'Alla krav',
   UrlText: 'Webbtext',
   NewSubDemand: 'Nytt underkrav',
   ParentDemand: 'Överkrav',
   DemandArchiveWarningText: 'Är du säker på att du vill arkivera detta krav med underliggande information?',
   DemandDeleteWarningText: 'Är du säker på att du vill radera detta krav?',
   ArchivedDemands: 'Arkiverade krav',
   Activate: 'Aktivera',
   DemandActivateWarningText: 'Är du säker på att du vill aktivera detta krav och underliggande information igen?',
   ActiveDemands: 'Aktiva krav',
   ActiveQuestions: 'Aktiva frågor',
   ImportedDemands: 'Importerade krav',
   Version: 'Version',

   // Status
   Warning: 'Varning',
   SendToDemandList: 'Skicka till kravlista',
   Save: 'Spara',
   Cancel: 'Avbryt',
   Clear: 'Rensa',
   Undo: 'Ångra',
   Delete: 'Radera',
   SavedSuccess: 'Sparning lyckad',
   SavedError: 'Sparningen misslyckades',
   SavedDemands: 'Kraven är sparade',
   DemandsSent: 'Kraven är skickade till kravlistan',
   QuestionSaved: 'Frågan är sparad',
   DemandSuccessfullyCleared: 'Den pågående kravhanteringen är rensad och nollställd',
   DemandClearingFailed: 'Något gick fel när den pågående kravhanteringen skulle rensas och nollställas',
   RequiredField: 'Vänligen, fyll i detta fält',
   UndoDemandChanges: 'Vill du ångra dina ändringar för detta krav?',
   SaveBeforeProceed: 'Spara dina ändringar innan du fortsätter',
   FormHasUnsavedChanges: 'Formuläret har osparade ändringar. Vänligen, spara ändringarna innan du fortsätter',
   LatestChangesWillBeUndone: 'Ändringarna återställs.',
   AreYouSureLatestChangesWillBeUndone:
      'Är du säker på att du vill fortsätta? Dina senaste ändringar kommer att återställas. Om du vill spara dina ändringar, vänligen klicka på avbryt och spara formuläret innan du fortsätter',
   UndoQuestionChanges: 'Vill du ångra dina ändringar i denna fråga?',
   UndoChanges: 'Ångra ändringar',
   Continue: 'Fortsätt',
   FetchFailed: 'Hämtning misslyckades',
   ItemDeleted: 'Objektet raderades framgångsrikt',
   DeleteError: 'Objektet kunde inte raderas',
   DeleteAttachment: 'Radera bilaga',
   CantChangeParentByDND: 'Du kan inte tilldela kravet en ny förälder genom drag and drop',
   CantDNDOnArchived: 'Du kan inte ändra ordningen på arkiverade krav',
   CantChangeParentOrLevelByDND: 'Du kan inte tilldela kravet en ny förälder eller nivå genom drag and drop',
   ActionNotSupported: 'Återgärden stöds inte',

   // Question
   QuestionsForDemand: 'Frågor till krav',
   NewQuestion: 'Ny fråga',
   BackgroundInformation: 'Bakgrundsinformation',
   ProposedAction: 'Föreslagen åtgärd',
   OrganisationType: 'Organisationsform',
   OrganisationSize: 'Organisationsstorlek',
   Process: 'Process',
   AlternativeProcesses: 'Alternativa processer',
   SNI: 'SNI',
   AdditionalModules: 'Tilläggsmoduler',
   Subscription: 'Abonnemang',
   Attachement: 'Bilaga',
   AddFile: 'Lägg till fil',
   NoQuestionsCreated: 'Det finns inga frågor kopplat till detta krav',
   NoQuestionChosen: 'Välj en fråga för att se innehållet',
   DeleteAttachmentText: 'Är du säker på att du vill radera bilagan?',

   //AnswerOptions
   YesNo: 'Ja/Nej',
   YesNoDontKnow: 'Ja/Nej/Vet inte',
   ComplianceSixAnswers: 'Efterlevnad 6 svar',
   ComplianceFourAnswers: 'Efterlevnad 4 svar',
   OwnAnswers: 'Egna svar',
   AddAnswerOption: 'Lägg till svarsalternativ',
   AnswerOption: 'Svarsalternativ',
   Info: 'Info',
   Value: 'Värde',
   DemandConnection: 'Koppla krav',
   UndoAnswerOptionChanges: 'Vill du ångra dina ändringar för detta svarsalternativ?',
   Catalog: 'Katalog',
   AnswerOptionList: 'Lista för svarsalternativ',
   Order: 'Ordning',
   CreateNewAnswerOption: 'Nytt svarsalternativ',
   ConnectDemandToAnswer: 'Ska man kunna koppla krav till detta svarsalternativ?',
   SelectAnAnswerOption: 'Välj ett svarsalternativ',
   DeleteDemandConnection: 'Ta bort kravkoppling',

   ExportDemandsAndQuestionsToExcel: 'Exportera krav och frågor till excel',
   ExportedSuccess: 'Export lyckades',
   ChangedTo: 'Ändrad till',
}
