import React from 'react'
import { Control, Controller } from 'react-hook-form'
import { Field, Combobox, Option } from '@fluentui/react-components'
type ControlledComboboxProps = {
   name: any
   control: Control<any>
   label: string
   options: any[]
   disabled: boolean
}

const ControlledCombobox = ({ name, control, label, options, disabled }: ControlledComboboxProps) => {
   return (
      <Controller
         name={name}
         control={control}
         render={({ field: { value, onChange } }) => (
            <Field label={label}>
               <Combobox
                  value={value ? options.find((x) => x.key === value).text : ''}
                  onOptionSelect={(e, d) => {
                     onChange(d.optionValue)
                  }}
                  selectedOptions={[value && value]}
                  disabled={disabled}
               >
                  {options.map((x) => (
                     <Option key={x.key} value={x.key}>
                        {x.text}
                     </Option>
                  ))}
               </Combobox>
            </Field>
         )}
      />
   )
}

export default ControlledCombobox
