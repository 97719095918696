import React, { useContext, useEffect, useId } from 'react'
import { tokens, shorthands, makeStyles, Toast, Toaster, useToastController, ToastBody, ToastTitle, ToastIntent, Divider } from '@fluentui/react-components'
import { Header } from '../components/common/header/Header'
import { Navigation } from '../components/common/navigation/Navigation'
import { DataContext } from '../App'
import { Loader } from '../components/common/spinner/Loader'
type PageProps = {
   children: JSX.Element[] | JSX.Element | string | string[]
   header?: JSX.Element
   subheader?: JSX.Element
}

const useStyles = makeStyles({
   page: {
      minHeight: '100vh',
   },
   main: {
      paddingBottom: '80px',
      paddingTop: '60px',
      minHeight: 'calc(100vh - 200px)',
   },
   mainPaddingLeft: {
      '@media screen and (min-width: 600px)': { paddingLeft: '60px' },
   },
   header: {
      position: 'sticky',
      top: '50px',
      backgroundColor: tokens.colorNeutralBackground1,
      zIndex: 2,
      width: '100%',
      marginBottom: tokens.spacingVerticalL,
      ...shorthands.padding(0, tokens.spacingHorizontalL),
      boxSizing: 'border-box',
   },
   mainPadding: { ...shorthands.padding(0, tokens.spacingHorizontalL) },
})

export const Page = ({ children, header, subheader }: PageProps) => {
   const classes = useStyles()
   const toasterId = useId()
   const { dispatchToast } = useToastController(toasterId)
   const dataContext = useContext(DataContext)
   const { message, initialLoading } = dataContext.state

   const notify = (intent: ToastIntent, title: string, text: string) => {
      dispatchToast(
         <Toast>
            <ToastTitle>{title}</ToastTitle>
            <ToastBody>{text}</ToastBody>
         </Toast>,
         { intent }
      )
   }
   useEffect(() => {
      if (message) {
         notify(message.intent, message.title, message.text)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [message])
   return (
      <div className={classes.page}>
         <Header />

         <div className={classes.mainPaddingLeft}>
            <main className={classes.main}>
               {initialLoading ? (
                  <Loader opacity={false} />
               ) : (
                  <>
                     {header && (
                        <header className={classes.header}>
                           {header}
                           <Divider />
                           {subheader}
                        </header>
                     )}
                     <div className={classes.mainPadding}>{children}</div>
                  </>
               )}
            </main>
         </div>
         <Navigation />
         <Toaster pauseOnHover position="top-end" toasterId={toasterId} />
      </div>
   )
}
