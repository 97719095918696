import React from 'react'
import { Button, tokens, shorthands, makeStyles } from '@fluentui/react-components'
import { t } from 'i18next'
import { Law, LawStatus } from '../../api/schemas/schema'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { UseFormReturn } from 'react-hook-form'
import { LawFormValues } from '../../pages/SelectedLaw'
import { BasicBreadcrumb } from '../common/breadcrumb/BasicBreadcrumb'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles({
   headerContent: {
      ...shorthands.padding(tokens.spacingVerticalS, 0),
   },
   buttonWrapper: {
      display: 'flex',
      justifyContent: 'space-between',
   },
   saveBtnWrapper: {
      display: 'flex',
      ...shorthands.gap(tokens.spacingHorizontalM),
   },
})
type SelectedLawHeaderProps = {
   onSubmit: (law: Law) => void
   selectedLaw: Law
   isDisabled: boolean
   openClearDemandModal: () => void
   demandForm: UseFormReturn<LawFormValues, any, undefined>
   isSubmitting: boolean
}
const SelectedLawHeader = ({ onSubmit, selectedLaw, isDisabled, openClearDemandModal, demandForm, isSubmitting }: SelectedLawHeaderProps) => {
   const classes = useStyles()
   const { id } = useParams()
   const {
      trigger,
      watch,
      formState: { isDirty, errors },
   } = demandForm
   return (
      <Grid className={classes.headerContent}>
         <GridItem size="8/12">
            <BasicBreadcrumb
               breadcrumbItems={[
                  { text: t('Laws'), path: '/laws?page=1' },
                  { text: selectedLaw?.sfsNr, path: `/laws/${id}` },
               ]}
            />
         </GridItem>
         <GridItem size="4/12" className={classes.buttonWrapper}>
            <Button
               disabled={
                  isDisabled ||
                  isSubmitting ||
                  (watch('LawDemand.text')?.length === 0 && watch('SelectedParagraphs').length === 0 && selectedLaw.status !== LawStatus.OngoingDemand)
               }
               appearance="outline"
               onClick={openClearDemandModal}
            >
               {t('Clear')}
            </Button>
            <span className={classes.saveBtnWrapper}>
               <Button
                  disabled={isDisabled || !isDirty || isSubmitting}
                  appearance="secondary"
                  onClick={() => {
                     onSubmit({ ...selectedLaw, status: LawStatus.OngoingDemand })
                  }}
               >
                  {t('Save')}
               </Button>
               <Button
                  type="submit"
                  appearance="primary"
                  disabled={isDisabled || watch('LawDemand.text')?.length === 0 || isSubmitting}
                  onClick={() => {
                     trigger()
                     if (Object.keys(errors).length === 0 && watch('LawDemand.text')?.length > 0) {
                        onSubmit({ ...selectedLaw, status: LawStatus.Demand })
                     }
                  }}
               >
                  {t('SendToDemandList')}
               </Button>
            </span>
         </GridItem>
      </Grid>
   )
}

export default SelectedLawHeader
