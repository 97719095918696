import React from 'react'
import { BreadcrumbDivider, BreadcrumbItem, BreadcrumbButton, Breadcrumb, makeStyles, tokens } from '@fluentui/react-components'
import { NavLink } from 'react-router-dom'
import { IBreadcrumb } from '../../../interfaces/IBreadcrumb'
const useStyles = makeStyles({
   specialHeader: { lineHeight: tokens.lineHeightHero700, fontSize: tokens.fontSizeBase500 },
})

type BasicBreadcrumbProps = {
   breadcrumbItems: IBreadcrumb[]
}
export const BasicBreadcrumb = ({ breadcrumbItems }: BasicBreadcrumbProps): JSX.Element => {
   const classes = useStyles()
   return (
      <Breadcrumb>
         {breadcrumbItems?.map((x, index) => (
            <>
               {index !== 0 ? <BreadcrumbDivider key={`divider-${index}`} /> : null}
               <BreadcrumbItem key={`crumb-${index}`}>
                  <BreadcrumbButton current={x.path.replace(' ', '%20') === window.location.pathname}>
                     <NavLink className={classes.specialHeader} style={{ textDecoration: 'none', color: 'black' }} to={x.path}>
                        {x.text}
                     </NavLink>
                  </BreadcrumbButton>
               </BreadcrumbItem>
            </>
         ))}
      </Breadcrumb>
   )
}
