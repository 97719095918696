import React, { useContext } from 'react'
import { makeStyles, tokens, Image, Button, shorthands, Menu, MenuTrigger, MenuPopover, MenuList, MenuItem, mergeClasses } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { msalInstance } from '../../../Root'
import { useMsal } from '@azure/msal-react'
import { SignOut24Regular, ChevronDown20Filled, Alert20Regular, Dismiss24Filled, Navigation24Filled } from '@fluentui/react-icons'
import { DataContext } from '../../../App'

const useStyles = makeStyles({
   header: {
      ...shorthands.padding(0, tokens.spacingHorizontalM, 0, tokens.spacingHorizontalM),
      width: '100vw',
      position: 'fixed',
      zIndex: 3,
      display: 'flex',
      justifyContent: 'space-between',
      boxSizing: 'border-box',
      height: '60px',
      backgroundColor: tokens.colorBrandBackground,
      boxShadow: tokens.shadow2,
      '& > *': {
         width: '33%',
      },
   },
   image: {
      height: '50px',
      ...shorthands.margin(0, 'auto'),
      display: 'block',
      '@media screen and (max-width: 1007px)': {
         height: '60px',
      },
   },
   flex: {
      display: 'flex',
   },
   flexEnd: {
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
      ...shorthands.gap(tokens.spacingHorizontalXXL),
      '@media screen and (max-width: 1007px)': {
         ...shorthands.gap(0),
      },
   },
   colorWhite: {
      color: tokens.colorNeutralForegroundInverted,
      '&:hover': {
         color: tokens.colorBrandForegroundInvertedHover,
      },
   },
   noneInPhone: {
      '@media screen and (max-width: 1007px)': {
         display: 'none',
      },
   },
   pointer: {
      cursor: 'pointer',
   },
   showOnPhone: {
      display: 'none',
      '@media screen and (max-width: 1007px)': {
         display: 'block',
      },
   },
})

export const Header = () => {
   const { accounts } = useMsal()
   const { t } = useTranslation()
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { navigationExpanded } = dataContext.state
   const toggleNavigation = () => {
      dataContext.setRootState((prev) => ({
         ...prev,
         navigationExpanded: !prev.navigationExpanded,
      }))
   }
   return (
      <header className={classes.header}>
         <div className={classes.flex}>
            <Button
               className={mergeClasses(classes.colorWhite, classes.pointer)}
               icon={navigationExpanded ? <Dismiss24Filled /> : <Navigation24Filled />}
               size="large"
               appearance="transparent"
               onClick={() => toggleNavigation()}
            />
         </div>
         <div>
            <Image alt={t('AppHeader')} src="/365bo.png" className={classes.image} />
         </div>
         <div className={classes.flexEnd}>
            {accounts.length > 0 ? (
               <>
                  <Button className={mergeClasses(classes.colorWhite)} icon={<Alert20Regular />} disabled appearance="transparent" />
                  <Menu>
                     <MenuTrigger>
                        <Button
                           className={mergeClasses(classes.noneInPhone, classes.colorWhite, classes.pointer)}
                           appearance="transparent"
                           icon={<ChevronDown20Filled />}
                           iconPosition="after"
                        >
                           {accounts[0].name}
                        </Button>
                     </MenuTrigger>
                     <MenuPopover>
                        <MenuList>
                           <MenuItem icon={<SignOut24Regular />} onClick={() => msalInstance.logoutRedirect()}>
                              {t('SignOut')}
                           </MenuItem>
                        </MenuList>
                     </MenuPopover>
                  </Menu>
               </>
            ) : null}
         </div>
      </header>
   )
}
