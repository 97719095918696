import React from 'react'
import { OptionOnSelectData, SelectionEvents } from '@fluentui/react-combobox'
import { Field, Dropdown, Option, Text, tokens } from '@fluentui/react-components'
import { Control, Controller } from 'react-hook-form'
import { DropdownOption } from '../../../helpers/enumHelper'
import { useTranslation } from 'react-i18next'

type DropdownProps = {
   label?: string
   options: DropdownOption[]
   ariaLabel?: string
   selectedOptions?: string[]
   disabled?: boolean
   onOptionSelect?: (event: SelectionEvents, data: OptionOnSelectData) => void
   multiselect: boolean
   defaultValue?: string
   className?: string
}
export const BasicDropdown = ({
   label,
   options,
   ariaLabel,
   selectedOptions,
   disabled,
   onOptionSelect,
   multiselect,
   defaultValue,
   className,
}: DropdownProps) => {
   if (label) {
      return (
         <Field label={label} className={className ?? ''}>
            <Dropdown
               multiselect={multiselect}
               disabled={disabled ?? false}
               aria-label={ariaLabel}
               value={selectedOptions.map((x) => options.find((o) => o.key.toString() === x)?.text).join(', ')}
               selectedOptions={selectedOptions}
               onOptionSelect={onOptionSelect}
               defaultValue={defaultValue}
               style={{ minWidth: 'auto', width: '100%' }}
            >
               {options.map((x) => (
                  <Option key={x.key} value={x.key.toString()} disabled={x.disabled ? x.disabled : false}>
                     {x.text}
                  </Option>
               ))}
            </Dropdown>
         </Field>
      )
   }

   return (
      <Dropdown
         multiselect={multiselect}
         disabled={disabled ?? false}
         aria-label={ariaLabel}
         value={selectedOptions.length > 0 ? selectedOptions.join(', ') : ''}
         selectedOptions={selectedOptions}
         onOptionSelect={onOptionSelect}
         defaultValue={defaultValue}
         className={className ?? ''}
         style={{ minWidth: 'auto', width: '100%' }}
      >
         {options.map((x) => (
            <Option key={x.key} value={x.key.toString()} disabled={x.disabled ? x.disabled : false}>
               {x.text}
            </Option>
         ))}
      </Dropdown>
   )
}
type ControlledDropdownProps = {
   name: any
   control: Control<any>
   label: string
   options: DropdownOption[]
   disabled?: boolean
   required?: boolean
}
export const ControlledDropDown = ({ name, control, label, options, disabled, required }: ControlledDropdownProps) => {
   const { t } = useTranslation()
   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false }}
         render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Field label={label} required={required}>
               <Dropdown
                  value={options.find((x) => x.key === (value ? Number(value) : value))?.text ?? ''}
                  onOptionSelect={(e, d) => {
                     onChange(Number(d.optionValue))
                  }}
                  selectedOptions={[value && value.toString()]}
                  disabled={disabled ?? false}
                  style={{ minWidth: 'auto' }}
               >
                  {options.map((x) => (
                     <Option key={x.key} value={x.key.toString()} disabled={x.disabled ? x.disabled : false}>
                        {x.text}
                     </Option>
                  ))}
               </Dropdown>
               <Text style={{ color: tokens.colorStatusDangerForeground1 }}>{error?.message}</Text>
            </Field>
         )}
      />
   )
}
