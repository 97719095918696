import React from 'react'
import { AnswerType, Demand, DemandArea, DemandType, Question, QuestionStatus } from '../../api/schemas/schema'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import StatusPill from '../../components/common/status/StatusPill'
import { statusBadgeColor } from '../../helpers/demandHelper'
import { enumValuesToTranslate } from '../../helpers/enumHelper'
import { TableCell, TableRow, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'

const useStyles = makeStyles({
   customTableRow: {
      backgroundColor: tokens.colorPaletteMarigoldBackground1,
      '&:hover': {
         backgroundColor: tokens.colorPaletteMarigoldBackground2,
      },
   },
})

type QuestionRowProps = {
   question: Question
   demand: Demand
}

export const QuestionRow = ({ question, demand }: QuestionRowProps) => {
   const classes = useStyles()
   const { t } = useTranslation()

   const questionFakeIdGenerator = (question: Question, demand: Demand): string => {
      const type = enumValuesToTranslate(DemandType, t, demand.type, { lng: 'sv' })[0]
      const area = enumValuesToTranslate(DemandArea, t, demand.area, { lng: 'sv' })[0]
      return `Q${question.order}D${demand.level}${type}${area}${demand.order < 10 ? '0' + demand.order : demand.order}`
   }

   return (
      <TableRow className={mergeClasses(classes.customTableRow)}>
         <TableCell>
            {
               <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${demand.id}?question=${question.order}`}>
                  {questionFakeIdGenerator(question, demand)}
               </NavLink>
            }
         </TableCell>
         <TableCell>
            <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${demand.id}?question=${question.order}`}>
               {question.text.length > 100 ? question.text.substring(0, 100) : question.text}
            </NavLink>
         </TableCell>
         <TableCell>{question.organisationSizes.map((x) => x.organisationSize.text).join(', ')}</TableCell>

         <TableCell>{question.organisationTypes.map((x) => x.organisationType.text).join(', ')}</TableCell>
         <TableCell>{question?.process?.process?.text && question?.process?.process?.text}</TableCell>
         <TableCell>{enumValuesToTranslate(AnswerType, t, question?.answerType)}</TableCell>
         <TableCell>
            <StatusPill color={statusBadgeColor(question.status)}>{enumValuesToTranslate(QuestionStatus, t, question.status)}</StatusPill>
         </TableCell>
      </TableRow>
   )
}
