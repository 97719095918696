import React from 'react'
import { Checkbox, Divider, Text, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Grid } from '../common/grid/Grid'
import { GridItem } from '../common/grid/GridItem'
import { t } from 'i18next'
import { DemandSubType, Paragraph } from '../../api/schemas/schema'
import { TextInput } from '../common/inputs/TextInput'
import { ControlledDropDown } from '../common/inputs/Dropdown'
import { enumValuesToOptions } from '../../helpers/enumHelper'
import { Control, UseFieldArrayReturn } from 'react-hook-form'
import { LawFormValues } from '../../pages/SelectedLaw'
import { getNewSecurityDemand } from '../../helpers/lawHelper'
type ParagraphProps = {
   item: Paragraph
   control: Control<LawFormValues>
   selected: boolean
   selectedParagraphsFieldArray: UseFieldArrayReturn<LawFormValues, 'SelectedParagraphs', 'id'>
   isDisabled: boolean
}
const useStyles = makeStyles({
   marginTop: {
      marginTop: tokens.spacingVerticalM,
   },
   flex: {
      display: 'flex',
      ...shorthands.gap(tokens.spacingHorizontalS),
      alignItems: 'flex-start',
   },
})
export const ParagraphComponent = ({ item, control, selected, selectedParagraphsFieldArray, isDisabled }: ParagraphProps): JSX.Element => {
   const classes = useStyles()
   const { append, remove, fields } = selectedParagraphsFieldArray
   return (
      <Grid className={classes.marginTop}>
         <GridItem size="8/12" className={classes.flex}>
            <Checkbox
               disabled={isDisabled}
               checked={selected}
               onChange={() => {
                  if (!selected) {
                     append({
                        ...getNewSecurityDemand(DemandSubType.Paragraph),
                        text: item.paragraphText,
                        paragraphId: item.id,
                     })
                  } else {
                     remove(fields.findIndex((f) => f.paragraphId === item.id))
                  }
               }}
            />
            <section>
               <Text key={item.id}>{item.paragraphText}</Text>
               <br />
            </section>
         </GridItem>
         {selected && (
            <GridItem size="4/12">
               <ControlledDropDown
                  disabled={isDisabled}
                  label={t('SubType')}
                  options={enumValuesToOptions(DemandSubType, t)}
                  control={control}
                  name={`SelectedParagraphs.${fields.findIndex((x) => x.paragraphId === item.id)}.subType`}
               />
               <TextInput className={classes.marginTop} label={t('DemandLevel')} value={`D${'3'}`} disabled />
            </GridItem>
         )}
         <GridItem size="12/12">
            <Divider />
         </GridItem>
      </Grid>
   )
}
