import React from 'react'
import { Field, Input, InputOnChangeData, Textarea, TextareaOnChangeData } from '@fluentui/react-components'
import { Control, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

type TextInputProps = {
   label: string
   value: string
   onChange?: (ev: any, data: TextareaOnChangeData | InputOnChangeData) => void
   disabled?: boolean
   multiline?: boolean
   className?: string
   rows?: number
}

export const TextInput = ({ label, value, onChange, disabled, multiline, className, rows }: TextInputProps) => {
   return (
      <Field className={className ?? ''} style={{ fontWeight: 'semi' }} label={label}>
         {multiline ? (
            <Textarea rows={rows} value={value ?? ''} onChange={(e, d) => onChange && onChange(e, d)} resize={'vertical'} disabled={disabled ?? false} />
         ) : (
            <Input value={value ?? ''} onChange={(e, d) => onChange && onChange(e, d)} disabled={disabled ?? false} />
         )}
      </Field>
   )
}

type ControlledTextInputProps = {
   name: any
   control: Control<any>
   label?: string
   disabled?: boolean
   multiline?: boolean
   required?: boolean
   rows?: number
   type?: 'number' | 'search' | 'time' | 'text' | 'email' | 'password' | 'tel' | 'url' | 'date' | 'datetime-local' | 'month' | 'week'
}
export const ControlledTextInput = ({ name, control, disabled, label, multiline, required, rows, type }: ControlledTextInputProps) => {
   const { t } = useTranslation()
   return (
      <Controller
         name={name}
         control={control}
         rules={{ required: required ? t('RequiredField') : false }}
         render={({ field: { value, onChange } }) => (
            <Field label={label} required={required ?? false}>
               {multiline ? (
                  <Textarea rows={rows} value={value ?? ''} onChange={onChange} resize={'vertical'} disabled={disabled ?? false} />
               ) : (
                  <Input type={type} value={value ?? ''} onChange={onChange} disabled={disabled ?? false} />
               )}
            </Field>
         )}
      />
   )
}
