import React from 'react'
import {
   DataGrid,
   DataGridHeader,
   DataGridRow,
   DataGridHeaderCell,
   DataGridBody,
   DataGridCell,
   shorthands,
   tokens,
   makeStyles,
   Text,
   TableColumnDefinition,
   InfoLabel,
   mergeClasses,
   TableColumnSizingOptions,
} from '@fluentui/react-components'
import { Title } from '../texts/Title'
import { Pagination } from './Pagination'

const useStyles = makeStyles({
   content: {
      ...shorthands.padding(tokens.spacingVerticalL),
      backgroundColor: tokens.colorPaletteMarigoldBackground2,
      boxShadow: tokens.shadow8,
   },
   list: {
      ...shorthands.margin(tokens.spacingVerticalXXL, 0),
   },
})

type ListProps<T extends {}> = {
   columns: TableColumnDefinition<T>[]
   items: T[]
   onRowClick?: (item: T) => void
   title: string
   info?: string
   resizableColumns?: boolean
   subText?: string
   className?: string
   pagination?: boolean
   numberOfPages?: number
   columnSizingOptions?: TableColumnSizingOptions
}
export const List = <T extends {}>({
   columns,
   items,
   onRowClick,
   title,
   subText,
   info,
   className,
   pagination,
   numberOfPages,
   resizableColumns,
   columnSizingOptions,
}: ListProps<T>) => {
   const classes = useStyles()
   return (
      <section className={mergeClasses(classes.content, className ?? '')}>
         <Title as="h4">{title}</Title>
         {info && <InfoLabel info={info} size="large" />}

         {subText && (
            <>
               <br />
               <Text>{subText}</Text>
            </>
         )}
         <DataGrid
            items={items}
            columns={columns}
            // sortable
            focusMode="composite"
            className={classes.list}
            resizableColumns={resizableColumns ?? false}
            columnSizingOptions={columnSizingOptions}
            style={{ minWidth: 'auto' }}
         >
            <DataGridHeader>
               <DataGridRow>{({ renderHeaderCell }) => <DataGridHeaderCell>{renderHeaderCell()}</DataGridHeaderCell>}</DataGridRow>
            </DataGridHeader>
            <DataGridBody<T>>
               {({ item, rowId }) => (
                  <DataGridRow<T> key={rowId} onClick={() => onRowClick && onRowClick(item)}>
                     {({ renderCell }) => <DataGridCell>{renderCell(item)}</DataGridCell>}
                  </DataGridRow>
               )}
            </DataGridBody>
            {pagination && <Pagination numberOfPages={numberOfPages} />}
         </DataGrid>
      </section>
   )
}
