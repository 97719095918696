import React, { createContext, useEffect, useState } from 'react'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { GetInitialRootState, initialDataContext } from './helpers/ctxHelper'
import { Dashboard } from './pages/Dashboard'
import { SelectedLaw } from './pages/SelectedLaw'
import { Laws } from './pages/Laws'
import { ComplianceOfDemands } from './pages/Demand/ComplianceOfDemands'
import { IRootState } from './interfaces/IRootState'
import { IDataContext } from './interfaces/IDataContext'
import { NumberOfLaws } from './api/law'
import { handleMessage } from './helpers/stateHelper'
import { GetDemands } from './api/demand'
import { useTranslation } from 'react-i18next'
import { DemandQuestion } from './pages/DemandQuestion'
import { AnswerOptionList } from './pages/SystemSettings/AnswerOptionList'
import { Api, OrganisationSize } from './api/schemas/schema'
import AnswerOptionForm from './pages/Answer/AnswerOptionForm'
export const DataContext = createContext(initialDataContext)
const App = () => {
   const [state, setRootState] = useState<IRootState>(GetInitialRootState())
   const { t } = useTranslation()

   const dataContextValue: IDataContext = {
      state,
      setRootState,
      handleMessage,
   }
   const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })
   useEffect(() => {
      ;(async () => {
         setRootState((prev) => ({ ...prev, initialLoading: true }))
         try {
            Promise.all([
               await NumberOfLaws(),
               await GetDemands(),
               (await apiInstance.api.questionList()) as any,
               (await apiInstance.api.answerOptionList()) as any,
            ]).then(([numberOfLaws, demands, questions, answerOptions]) => {
               setRootState((prev) => ({
                  ...prev,
                  numberOfPages: Math.ceil(numberOfLaws / 50),
                  allLawsCount: numberOfLaws,
                  demands,
                  questions: questions.data,
                  answerOptions: answerOptions.data,
               }))
            })
         } catch (error: any) {
            handleMessage(setRootState, 'error', t('FetchFailed'), error.message)
         } finally {
            setRootState((prev) => ({ ...prev, initialLoading: false }))
         }
      })()
   }, [])

   useEffect(() => {
      if (state.initialLoading === false) {
         ;(async () => {
            try {
               Promise.all([
                  (await apiInstance.api.additionalModuleList()) as any,
                  (await apiInstance.api.subscriptionList()) as any,
                  (await apiInstance.api.subjectList()) as any,
                  (await apiInstance.api.organisationSizeList()) as any,
                  (await apiInstance.api.organisationTypeList()) as any,
                  (await apiInstance.api.leadershipPerspectiveList()) as any,
                  (await apiInstance.api.getApi3({ group: 'main group' })) as any,
                  (await apiInstance.api.processList()) as any,
               ]).then(([additionalModules, subscriptions, subjects, organisationSizes, organisationTypes, leadershipPerspectives, snis, processes]) => {
                  setRootState((prev) => ({
                     ...prev,
                     additionalModules: additionalModules.data,
                     subscriptions: subscriptions.data,
                     subjects: subjects.data,
                     organisationSizes: organisationSizes.data.sort((a: OrganisationSize, b: OrganisationSize) => {
                        const aTextValue = a.text === '500+' ? 500 : Number(a.text.split('-')[0])
                        const bTextValue = b.text === '500+' ? 500 : Number(b.text.split('-')[0])

                        return aTextValue - bTextValue
                     }),
                     organisationTypes: organisationTypes.data,
                     leadershipPerspectives: leadershipPerspectives.data,
                     snis: snis.data,
                     processes: processes.data,
                  }))
               })
            } catch (error: any) {
               handleMessage(setRootState, 'error', t('FetchFailed'), error.message)
            }
         })()
      }
   }, [state.initialLoading])
   const router = createBrowserRouter([
      {
         path: '/',
         element: <Dashboard />,
         //   loader: <Spinner />,
      },
      {
         path: '/laws/:id',
         element: <SelectedLaw />,
         //   loader: <Spinner />,
      },
      {
         path: '/laws',
         element: <Laws />,
         //   loader: <Spinner />,
      },
      {
         path: '/demands/:id',
         element: <DemandQuestion />,
         //   loader: <Spinner />,
      },
      {
         path: '/demands',
         element: <ComplianceOfDemands />,
         //   loader: <Spinner />,
      },
      {
         path: '/systemsettings',
         element: <AnswerOptionList />,
         //   loader: <Spinner />,
      },
      {
         path: '/systemsettings/answeroptions/:id',
         element: <AnswerOptionForm />,
         //   loader: <Spinner />,
      },
   ])
   return (
      <DataContext.Provider value={dataContextValue}>
         <RouterProvider router={router} />
      </DataContext.Provider>
   )
}

export default App
