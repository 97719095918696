import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Title } from '../common/texts/Title'
import { Text } from '@fluentui/react-components'
import { Law } from '../../api/schemas/schema'
import { NavLink } from 'react-router-dom'
type LawInformationProps = {
   law: Law
}
export const LawInformation = ({ law }: LawInformationProps) => {
   const { t } = useTranslation()
   return (
      <article>
         <Title as="h3">{law?.title}</Title>
         <div>
            <Text as="p" weight="semibold">
               {`${t('SfsNo')}: `}
            </Text>
            <Text as="p">{law?.sfsNr}</Text>
         </div>
         <div>
            <Text as="p" weight="semibold">
               {`${t('Department')}: `}
            </Text>
            <Text as="p">{law?.department}</Text>
         </div>
         <div>
            <Text as="p" weight="semibold">
               {`${t('IssuedAt')}: `}
            </Text>
            <Text as="p">{law?.issued ? dayjs(law?.issued).format('YYYY-MM-DD') : ''}</Text>
         </div>
         <div>
            <Text as="p" weight="semibold">
               {`${t('ModificationRegister')}: `}
            </Text>
            <Text as="p">{law?.title}</Text>
         </div>
         <div>
            <Text as="p" weight="semibold">
               {`${t('ModifiedAt')}: `}
            </Text>
            <Text as="p">{law?.changed}</Text>
         </div>
         <div>
            <Text as="p" weight="semibold">
               {`${t('WebLink')}: `}
            </Text>
            <NavLink target="blank" to={`${law?.url}`}>
               {law?.sfsNr}
            </NavLink>
         </div>
      </article>
   )
}
