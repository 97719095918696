import { Badge, tokens, mergeClasses, Switch, makeStyles, shorthands } from '@fluentui/react-components'

import { NavLink, useNavigate } from 'react-router-dom'
import { DemandType, DemandSubType, DemandArea, DemandStatus, Demand, Origin } from '../../api/schemas/schema'
import StatusPill from '../../components/common/status/StatusPill'
import { addDemandFromList, fakeIdGenerator, statusBadgeColor } from '../../helpers/demandHelper'
import { enumValuesToTranslate } from '../../helpers/enumHelper'
import { ChevronDown20Filled, ChevronRight20Filled, AddCircle24Filled } from '@fluentui/react-icons'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataContext } from '../../App'
import { Text } from '../../components/common/texts/Text'
import { IExtendedColumn } from '../../interfaces/IColumn'
import { ReorderQuestionRow } from '../Question/ReorderQuestionRow'
import { useMsal } from '@azure/msal-react'
const useStyles = makeStyles({
   brand: { backgroundColor: tokens.colorBrandBackground },
   informative: { backgroundColor: tokens.colorNeutralBackground2Pressed, color: tokens.colorNeutralForeground1 },
   gold: { backgroundColor: tokens.colorPaletteGoldBackground2, color: tokens.colorNeutralForeground1 },
   lvlMargin: { marginLeft: tokens.spacingHorizontalM },
   verticalAlignTopPT: { verticalAlign: 'top', paddingTop: '0.4em' },
   verticalAlignTopPT1: { verticalAlign: 'top', paddingTop: '0.6em' },
   verticalAlignTop: { verticalAlign: 'top' },
   cursorPointer: { cursor: 'pointer' },
   badge: { minWidth: '40px', width: 'fit-content' },
   bold: { fontWeight: tokens.fontWeightBold },
   plusButtonContainer: {
      cursor: 'pointer',
      width: '100%',
      ...shorthands.border('none'),
      display: 'flex',
   },
   plusButton: {
      position: 'relative',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
   },
})
type ReorderRowProps = {
   item: Demand
   expanded: boolean
   toggleRowExpansion: (id: string) => void
   columns: IExtendedColumn[]
   rowClass: string
   showButton: string
   setShowButton: React.Dispatch<React.SetStateAction<string>>
}

export const ReorderRowContent = ({ item, expanded, toggleRowExpansion, rowClass, columns, showButton, setShowButton }: ReorderRowProps) => {
   const dataContext = useContext(DataContext)

   const { demands, selectedTab, questions } = dataContext.state
   const classes = useStyles()
   const { t } = useTranslation()
   const navigate = useNavigate()
   const { accounts } = useMsal()

   const [questionExpanded, setQuestionExpanded] = useState<boolean>(false)
   const handleAddDemand = async (demand: Demand) => {
      const newDemand: Demand = {
         area: demand?.area,
         level: demand?.level,
         modifiedBy: accounts[0].name,
         order: demand.order + 1,
         origin: Origin.Sweden,
         parentId: demand?.parentId,
         source: '',
         type: demand?.type,
         subType: 0,
         text: '',
         url: '',
         urlText: '',
      }
      const updateDemands = demands.filter((x) => x.parentId === demand.parentId && x.order > demand.order)
      const updatedDemands = updateDemands.map((updateDemand) => {
         return {
            ...updateDemand,
            order: updateDemand.order + 1,
         }
      })
      await addDemandFromList(newDemand, updatedDemands, dataContext, t, navigate)
   }
   const levelBadgeColor = (lvl: number) => {
      switch (lvl) {
         case 1:
            return classes.brand
         case 2:
            return classes.gold
         case 3:
            return classes.informative
         default:
            break
      }
   }
   const getIcon = (item: Demand) => {
      switch (selectedTab) {
         case 'activeDemands':
            return demands.some((x) => x.parentId === item.id && x.status !== DemandStatus.Archived) ? (
               expanded ? (
                  <ChevronDown20Filled />
               ) : (
                  <ChevronRight20Filled />
               )
            ) : null
         case 'archivedDemands':
            return demands.filter((x) => item.status === DemandStatus.Archived && x.parentId === item.id).length > 0 ? (
               expanded ? (
                  <ChevronDown20Filled />
               ) : (
                  <ChevronRight20Filled />
               )
            ) : null
         default:
            break
      }
   }
   return (
      <>
         <div className={rowClass}>
            <span style={{ minWidth: columns[0].minWidth, width: columns[0].width }}>
               <Badge
                  style={{ marginLeft: `calc(${tokens.spacingHorizontalM} * ${item.level - 1})` }}
                  className={mergeClasses(levelBadgeColor(item.level), classes.cursorPointer, classes.badge)}
                  icon={getIcon(item)}
                  onClick={() => toggleRowExpansion(item.id)}
               >
                  D{item.level}
               </Badge>
            </span>
            <span style={{ minWidth: columns[1].minWidth, width: columns[1].width }}>
               <Switch
                  disabled={questions.filter((x) => x.parentId === item.id).length === 0}
                  checked={questionExpanded}
                  onChange={() => setQuestionExpanded((prevExpanded) => !prevExpanded)}
               />
            </span>
            <span style={{ minWidth: columns[2].minWidth, width: columns[2].width }}>
               <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${item.id}`}>
                  {fakeIdGenerator(
                     item,
                     t,
                     demands.find((x) => x.id === item.parentId)
                  )}
               </NavLink>
            </span>
            <span style={{ minWidth: columns[3].minWidth, width: columns[3].width }}>
               {
                  <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${item.id}`}>
                     {item.text.length > 100 ? <Text> {item.text.length > 100 ? item.text.substring(0, 300) + '...' : item.text}</Text> : item.text}
                  </NavLink>
               }
            </span>
            <span style={{ minWidth: columns[4].minWidth, width: columns[4].width }}></span>
            <span style={{ minWidth: columns[5].minWidth, width: columns[5].width }}>{enumValuesToTranslate(DemandType, t, item.type)}</span>
            <span style={{ minWidth: columns[6].minWidth, width: columns[6].width }}>{enumValuesToTranslate(DemandSubType, t, item.subType)}</span>
            <span style={{ minWidth: columns[7].minWidth, width: columns[7].width }}>
               {item.source.length > 100 ? `${item.source.slice(0, 100)}...` : item.source}
            </span>
            <span style={{ minWidth: columns[8].minWidth, width: columns[8].width }}>{enumValuesToTranslate(DemandArea, t, item.area)}</span>
            <span style={{ minWidth: columns[9].minWidth, width: columns[9].width }}>
               <StatusPill color={statusBadgeColor(item.status)}>{enumValuesToTranslate(DemandStatus, t, item.status)}</StatusPill>
            </span>
         </div>
         {showButton === item.id && item.level > 1 && item.status !== DemandStatus.Archived ? (
            <div className={mergeClasses(classes.plusButtonContainer)} onClick={async () => await handleAddDemand(item)}>
               <span style={{ height: '1px', width: '100%' }} onMouseLeave={() => setShowButton('')}>
                  <AddCircle24Filled className={classes.plusButton} />
               </span>
            </div>
         ) : null}
         {questionExpanded &&
            questions
               .filter((x) => x.parentId === item.id)
               .map((question) => <ReorderQuestionRow columns={columns} rowClass={rowClass} question={question} demand={item} key={question.id} />)}
      </>
   )
}
