import React from 'react'
import { Menu, MenuTrigger, MenuPopover, MenuList } from '@fluentui/react-components'

type ContextualMenuProps = {
   menuTrigger: JSX.Element
   menuItems: JSX.Element | JSX.Element[]
}
export const ContextualMenu = ({ menuTrigger, menuItems }: ContextualMenuProps) => {
   return (
      <Menu>
         <MenuTrigger>{menuTrigger}</MenuTrigger>

         <MenuPopover>
            <MenuList>{menuItems}</MenuList>
         </MenuPopover>
      </Menu>
   )
}
