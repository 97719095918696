import SiteConstants from '../assets/SiteConstants'
import { Law } from './schemas/schema'
import axios from 'axios'

export const GetLaws = async (page: number): Promise<Law[]> => {
   try {
      const response = await axios.get(`/${SiteConstants.LAWS}?page=${page}`)
      const laws: Law[] = response.data
      return laws
   } catch (error) {
      return []
   }
}

export const GetFilteredLaws = async (statuses: string[], statusImports: string[], page: number): Promise<Law[]> => {
   try {
      const statusQueryString = `&statuses=${statuses?.join('&statuses=')}`
      const statusImportsQueryString = `&statusImports=${statusImports?.join('&statusImports=')}`
      let queryString = `page=${page}`

      if (statuses.length > 0) queryString = queryString + statusQueryString
      if (statusImports.length > 0) queryString = queryString + statusImportsQueryString

      const response = await axios.get(`/${SiteConstants.LAWS}/status?${queryString}`)

      const laws: Law[] = response.data
      return laws
   } catch (error) {
      return []
   }
}

export const GetLawById = async (id: string): Promise<Law> => {
   try {
      const response = await axios.get(`/${SiteConstants.LAWS}/${id}`)
      const item: Law = response.data
      return item
   } catch (error) {
      throw error
   }
}

export const UpdateLaw = async (updatedLaw: Law): Promise<Law> => {
   try {
      const law = await axios.put(`/${SiteConstants.LAWS}`, updatedLaw, {
         headers: {
            'Content-Type': 'application/json',
         },
      })
      return law
   } catch (error) {
      throw error
   }
}

export const NumberOfLaws = async (statuses?: string[], statusImports?: string[]): Promise<number> => {
   try {
      let url = `/${SiteConstants.LAWS}/${SiteConstants.COUNT}?`

      if (statuses && statuses.length > 0) {
         const statusQueryString = statuses.join('&statuses=')
         url += `&statuses=${statusQueryString}`
      }
      if (statusImports && statusImports.length > 0) {
         const statusImportsQueryString = statusImports.join('&statusImports=')
         url += `&statusImports=${statusImportsQueryString}`
      }

      const response = await axios.get(url)
      const count = response.data
      return count
   } catch (error) {
      throw error
   }
}
