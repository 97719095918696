import React from 'react'
import { t } from 'i18next'
import { DemandSubType, Origin, DemandType, Law, DemandArea } from '../../api/schemas/schema'
import { enumValuesToOptions } from '../../helpers/enumHelper'
import { GridItem } from '../common/grid/GridItem'
import { ControlledDropDown } from '../common/inputs/Dropdown'
import { ControlledTextInput, TextInput } from '../common/inputs/TextInput'
import { LawInformation } from './LawInformation'
import { UseFormReturn } from 'react-hook-form'
import { LawFormValues } from '../../pages/SelectedLaw'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'

const useStyles = makeStyles({
   paddingL: {
      '@media screen and (min-width: 600px)': { ...shorthands.padding(tokens.spacingVerticalL, tokens.spacingHorizontalL) },
   },
   smallGap: {
      display: 'flex',
      flexDirection: 'column',
      ...shorthands.gap(tokens.spacingHorizontalM),
      '@media screen and (min-width: 600px)': {
         ...shorthands.gap(tokens.spacingHorizontalM),
      },
   },
})
type LawControlsProps = {
   demandForm: UseFormReturn<LawFormValues, any, undefined>
   selectedLaw: Law
   isDisabled: boolean
}
export const LawControls = ({ demandForm, selectedLaw, isDisabled }: LawControlsProps) => {
   const { control, watch } = demandForm
   const classes = useStyles()
   return (
      <>
         <GridItem size="4/12">
            <LawInformation law={selectedLaw} />
         </GridItem>
         <GridItem size="4/12">
            <ControlledTextInput
               control={control}
               name={'LawDemand.text'}
               label={t('Demand')}
               multiline={true}
               disabled={isDisabled}
               required={true}
               rows={10}
            />
         </GridItem>
         <GridItem size="4/12" className={classes.smallGap}>
            <TextInput label={t('DemandLevel')} value={`D${watch('LawDemand.level')}`} disabled />
            <ControlledDropDown
               control={control}
               name={'LawDemand.type'}
               options={enumValuesToOptions(DemandType, t)}
               label={t('DemandType')}
               disabled={true}
            />
            <ControlledDropDown
               control={control}
               name={'LawDemand.area'}
               options={enumValuesToOptions(DemandArea, t)}
               label={t('DemandArea')}
               disabled={isDisabled}
            />
            <ControlledDropDown
               control={control}
               name={'LawDemand.subType'}
               options={enumValuesToOptions(DemandSubType, t)}
               label={t('SubType')}
               disabled={isDisabled}
            />
            <ControlledDropDown control={control} name={'LawDemand.origin'} options={enumValuesToOptions(Origin, t)} label={t('Origin')} disabled={true} />
         </GridItem>
      </>
   )
}
