import React, { useContext, useEffect, useState } from 'react'
import { Tab, TabList, makeStyles, mergeClasses, shorthands, tokens, Text } from '@fluentui/react-components'
import { DataContext } from '../../../App'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
   Home24Regular,
   Book24Regular,
   ClipboardTaskListLtr24Regular,
   TableSettings24Regular,
   Home24Filled,
   Book24Filled,
   ClipboardTaskListLtr24Filled,
   TableSettings24Filled,
} from '@fluentui/react-icons'
const useStyles = makeStyles({
   nav: {
      position: 'fixed',
      top: '60px',
      width: '60px',
      boxShadow: tokens.shadow8,
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: tokens.colorNeutralBackground1,
      zIndex: 2,
      height: 'calc(100vh - 60px)',
      justifyContent: 'space-between',
   },
   expanded: {
      width: '316px',
      backgroundColor: tokens.colorNeutralBackground1,
   },
   smallNavBtnWrapper: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '50px',
      width: '64px',
   },
   tabItem: {
      ...shorthands.padding(tokens.spacingHorizontalM, tokens.spacingHorizontalM, tokens.spacingHorizontalM, tokens.spacingHorizontalXL),
      '::after': {
         content: '""', // Note the nested quotes
         width: '8px',
         ...shorthands.borderRadius(0),
      },
   },
   hideInPhone: {
      display: 'none',
      '@media screen and (min-width: 600px)': { display: 'block' },
   },
   chrevron: {
      paddingLeft: tokens.spacingHorizontalXL,
   },
   centerVersion: { ...shorthands.margin(0, 'auto') },
   tabList: {
      width: '100%',

      '& button': {
         paddingLeft: '18px',
      },
      '@media screen and (max-width: 1007px)': {
         justifyContent: 'space-around',
         '& button': {
            paddingLeft: '10px',
         },
      },
   },
})

export const Navigation = () => {
   const classes = useStyles()
   const data = useContext(DataContext)
   const { t } = useTranslation()
   const { navigationExpanded } = data.state
   console.log('navigationExpanded', navigationExpanded)
   const navigate = useNavigate()
   const packageJson = require('../../../../package.json')
   const appVersion = packageJson.version
   const { pathname } = window.location
   const [selectedTab, setSelectedTab] = useState<string>(window.location.pathname)
   useEffect(() => {
      setSelectedTab(`/${window.location.pathname.split('/')[1]}`)
   }, [])
   return (
      <>
         <aside className={mergeClasses(classes.nav, navigationExpanded ? classes.expanded : '')}>
            <TabList vertical selectedValue={selectedTab} onTabSelect={(e, data) => navigate(data.value)} size="large" className={classes.tabList}>
               <Tab icon={pathname === '/' ? <Home24Filled /> : <Home24Regular />} value={'/'}>
                  {navigationExpanded && t('Dashboard')}
               </Tab>
               <Tab icon={pathname === '/laws' ? <Book24Filled /> : <Book24Regular />} value={'/laws'}>
                  {navigationExpanded && t('Laws')}
               </Tab>
               <Tab icon={pathname === '/demands' ? <ClipboardTaskListLtr24Filled /> : <ClipboardTaskListLtr24Regular />} value={'/demands'}>
                  {navigationExpanded && t('DemandQuestionList')}
               </Tab>
               <Tab icon={pathname === '/systemsettings' ? <TableSettings24Filled /> : <TableSettings24Regular />} value={'/systemsettings'}>
                  {navigationExpanded && t('SystemSettings')}
               </Tab>
            </TabList>
            <Text size={200} className={classes.centerVersion}>
               v {appVersion}
            </Text>
         </aside>
      </>
   )
}
