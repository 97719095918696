import React from 'react'
import { useSearchParams } from 'react-router-dom'
import { Text, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components'
import { Arrow } from './Arrow'

const useStyles = makeStyles({
   pagination: {
      listStyleType: 'none',
      display: 'flex',
      paddingTop: tokens.spacingVerticalXXL,
      ...shorthands.paddingInline(0),
      justifyContent: 'center',
      ...shorthands.gap(tokens.spacingHorizontalXS),
   },
   link: {
      ...shorthands.padding(tokens.spacingHorizontalXS),
      cursor: 'pointer',
      fontSize: tokens.fontSizeBase500,
   },
   arrow: {
      fontSize: tokens.fontSizeBase600,
   },
})
type PaginationProps = {
   numberOfPages: number
}
export const Pagination = ({ numberOfPages }: PaginationProps): JSX.Element => {
   const classes = useStyles()
   const [search, setSearch] = useSearchParams()
   const page = parseInt(search.get('page'))
   const numberOfPagesVisible = 26 // plus 1 och numberOfPages
   const getPagination = (): JSX.Element[] => {
      const elements: JSX.Element[] = []
      // 1 + 23 + 1
      for (let i = 1; i <= numberOfPages; i++) {
         if (i === 1 && i !== page) {
            elements.push(
               <li
                  key={`arrow-left-${i}`}
                  onClick={() => {
                     setSearch({ page: (page - 1).toString() })
                  }}
               >
                  <Arrow className={mergeClasses(classes.link, classes.arrow)} direction="left" />
               </li>
            )
         }
         if (i === numberOfPages && page < i - Math.floor(numberOfPagesVisible / 2)) {
            elements.push(
               <li key={`dot-dot-${i}`}>
                  <Text className={classes.link}>...</Text>
               </li>
            )
         }

         const isActive = page === i
         if (
            i === 1 ||
            i === numberOfPages ||
            (i <= numberOfPagesVisible && page <= Math.floor(numberOfPagesVisible / 2)) ||
            (page >= numberOfPages - Math.floor(numberOfPagesVisible / 2) && i > numberOfPages - numberOfPagesVisible) ||
            (page > Math.floor(numberOfPagesVisible / 2) && i < page + Math.floor(numberOfPagesVisible / 2) && i > page - Math.floor(numberOfPagesVisible / 2))
         ) {
            elements.push(
               <li
                  key={`page-${i}`}
                  onClick={() => {
                     setSearch({ page: i.toString() })
                  }}
               >
                  <Text className={classes.link} weight={isActive ? 'bold' : 'regular'}>
                     {i}
                  </Text>
               </li>
            )
         }
         if (i === 1 && page > i + Math.floor(numberOfPagesVisible / 2)) {
            elements.push(
               <li key={`dot-${i}`}>
                  <Text className={classes.link}>...</Text>
               </li>
            )
         }

         if (i === numberOfPages && i !== page) {
            elements.push(
               <li
                  key={`arrow-right-${i}`}
                  onClick={() => {
                     setSearch({ page: (page + 1).toString() })
                  }}
               >
                  <Arrow className={mergeClasses(classes.link, classes.arrow)} direction="right" />
               </li>
            )
         }
      }
      return elements
   }
   return <ul className={classes.pagination}>{getPagination()}</ul>
}
