import { createTableColumn } from '@fluentui/react-components'
import dayjs from 'dayjs'
import { TFunction, t } from 'i18next'
import { NavLink } from 'react-router-dom'
import { AvatarName } from '../components/common/avatar/AvatarName'
import { BasicDropdown } from '../components/common/inputs/Dropdown'
import { Text } from '../components/common/texts/Text'
import { enumValuesToOptions, enumValuesToTranslate, getStatuses } from './enumHelper'
import {
   AnswerOption,
   AnswerType,
   Api,
   Demand,
   DemandArea,
   DemandStatus,
   DemandSubType,
   DemandType,
   Law,
   LawStatus,
   Origin,
   Paragraph,
   StatusImport,
} from '../api/schemas/schema'
import StatusPill from '../components/common/status/StatusPill'
import { LawFormValues } from '../pages/SelectedLaw'
import { UpdateLaw } from '../api/law'
import { AddItemsToCollection, handleMessage, setLoading } from './stateHelper'
import { AccountInfo } from '@azure/msal-browser'
import { IRootState } from '../interfaces/IRootState'
import { AddDemand, UpdateDemand } from '../api/demand'
import { UpdateParagraph } from '../api/paragraph'
import { IDataContext } from '../interfaces/IDataContext'
import { UseFormReset } from 'react-hook-form'
import { deleteDemands, deleteUnselectedParagraphDemands } from './demandHelper'

export const getLawColumns = (
   setRootState: React.Dispatch<React.SetStateAction<IRootState>>,
   user: AccountInfo,
   setLaws: React.Dispatch<React.SetStateAction<Law[]>>
) => [
   createTableColumn<Law>({
      columnId: 'title',
      compare: (a, b) => {
         return a.title.localeCompare(b.title)
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('Title')}</Text>
      },
      renderCell: (item) => {
         return (
            <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/laws/${item.id}`}>
               {item.title.length > 0 ? item.title : item.sfsNr}
            </NavLink>
         )
      },
   }),
   createTableColumn<Law>({
      columnId: 'link',
      compare: (a, b) => {
         return a.sfsNr.localeCompare(b.sfsNr)
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('WebLink')}</Text>
      },
      renderCell: (item) => {
         return (
            <NavLink target="blank" to={`${item.url}`}>
               {item.sfsNr}
            </NavLink>
         )
      },
   }),
   createTableColumn<Law>({
      columnId: 'changed',
      compare: (a, b) => {
         return a.sfsNr.localeCompare(b.sfsNr)
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('ChangedTo')}</Text>
      },
      renderCell: (item) => {
         return <Text>{item.changed}</Text>
      },
   }),
   createTableColumn<Law>({
      columnId: 'status',
      compare: (a, b) => {
         const valueA = getStatuses('Law', t).find((x) => Number(x.key) === a.status)?.text ?? ''
         const valueB = getStatuses('Law', t).find((x) => Number(x.key) === b.status)?.text ?? ''

         return valueA.localeCompare(valueB)
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('Status')}</Text>
      },

      renderCell: (item) => {
         return (
            <BasicDropdown
               disabled={item.status === LawStatus.Demand || item.status === LawStatus.OngoingDemand || item.status === LawStatus.Archived}
               selectedOptions={[getStatuses('Law', t).find((x) => Number(x.key) === item.status)?.text]}
               ariaLabel={t('Status')}
               options={getStatuses('Law', t).filter((x) => !x.disabled)}
               multiselect={false}
               onOptionSelect={async (e, d) => {
                  await updateLawStatus(setRootState, d.optionValue, item, user, setLaws)
               }}
            />
         )
      },
   }),
   createTableColumn<Law>({
      columnId: 'modified',
      compare: (a, b) => {
         const dateA = new Date(a.modified ?? new Date())
         const dateB = new Date(b.modified ?? new Date())
         return dateA.getTime() - dateB.getTime()
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('Modified')}</Text>
      },
      renderCell: (item) => {
         return item.modified ? <Text>{dayjs(item.modified).format('YYYY-MM-DD')}</Text> : null
      },
   }),
   createTableColumn<Law>({
      columnId: 'changedBy',
      compare: (a, b) => {
         const valueA = a.changedBy ?? ''
         const valueB = b.changedBy ?? ''

         return valueA.localeCompare(valueB)
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('ModifiedBy')}</Text>
      },
      renderCell: (item) => {
         return item.changedBy ? <AvatarName name={item.changedBy} /> : null
      },
   }),
   createTableColumn<Law>({
      columnId: 'statusImport',
      compare: (a, b) => {
         const valueA = enumValuesToOptions(StatusImport, t).find((x) => x.key === a.statusImport)?.text ?? ''
         const valueB = enumValuesToOptions(StatusImport, t).find((x) => x.key === b.statusImport)?.text ?? ''

         return valueA.localeCompare(valueB)
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('StatusImport')}</Text>
      },
      renderCell: (item) => {
         if (!item.statusImport || item.statusImport === StatusImport.Empty) return null
         return (
            <StatusPill color={item.statusImport === StatusImport.New ? 'success' : item.statusImport === StatusImport.Revoked ? 'danger' : 'warning'}>
               {enumValuesToOptions(StatusImport, t).find((x) => x.key === item.statusImport)?.text}
            </StatusPill>
         )
      },
   }),
]
export const getNewSecurityDemand = (demandSubType: DemandSubType): Demand => ({
   area: DemandArea.Security,
   level: demandSubType === DemandSubType.Law ? 2 : 3,
   modifiedBy: null,
   order: 0,
   origin: Origin.Sweden,
   parentId: null,
   source: '',
   type: DemandType.PublicAuthorityDemand,
   subType: demandSubType,
   text: '',
   url: '',
   urlText: '',
})

export const updateLawStatus = async (
   setRootState: React.Dispatch<React.SetStateAction<IRootState>>,
   status: string,
   law: Law,
   user: AccountInfo,
   setLaws: React.Dispatch<React.SetStateAction<Law[]>>
) => {
   const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })
   setLoading(setRootState, true)
   try {
      law.status = Number(status)
      law.paragraphs = []
      law.changedBy = user.name
      law.modified = new Date().toISOString()

      const updatedLaw: Law = (await apiInstance.api.putApi(law)).data
      setLaws((prevLaws) => {
         return prevLaws.map((x) => {
            if (x.id === law.id) {
               return {
                  ...updatedLaw,
               }
            }
            return x
         })
      })
   } catch (error) {
   } finally {
      setLoading(setRootState, false)
   }
}
export const saveLawAndParagraphDemands = async (
   dataContext: IDataContext,
   reset: UseFormReset<LawFormValues>,
   setSelectedLaw: React.Dispatch<React.SetStateAction<Law>>,
   setParagraphs: React.Dispatch<React.SetStateAction<Paragraph[]>>,
   t: TFunction<'translation', undefined>,
   data: LawFormValues,
   paragraphs: Paragraph[],
   law: Law,
   user: AccountInfo,
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
) => {
   const { setRootState } = dataContext
   const { demands } = dataContext.state
   const { LawDemand, SelectedParagraphs } = data
   try {
      const d1: Demand = demands.find((x) => x.area === data.LawDemand.area && x.type === DemandType.PublicAuthorityDemand && x.level === 1)
      LawDemand.parentId = d1.id //Myndighetskrav D1 id
      LawDemand.modifiedBy = user.name
      LawDemand.source = law.title
      LawDemand.url = law.url
      LawDemand.urlText = law.sfsNr
      LawDemand.status = law.status === LawStatus.OngoingDemand ? DemandStatus.SavedButNotSure : DemandStatus.Ongoing
      LawDemand.order = demands.filter((x) => x.parentId === d1.id).length + 1
      LawDemand.version = law.changed
      const demand: Demand = LawDemand.id ? await UpdateDemand(LawDemand) : await AddDemand(LawDemand)

      law.paragraphs = []
      law.demandId = demand.id
      await UpdateLaw(law)

      const paragraphDemands = SelectedParagraphs.map((p, i) => ({
         ...p,
         parentId: demand.id,
         modifiedBy: user.name,
         order: i + 1,
         source: law.title,
         url: law.url,
         urlText: law.sfsNr,
         status: law.status === LawStatus.OngoingDemand ? DemandStatus.SavedButNotSure : DemandStatus.Ongoing,
         area: data.LawDemand.area,
      }))
      const addedParagraphDemands: Array<Demand & { paragraphId: number }> = await Promise.all(
         paragraphDemands.map(async (x) => {
            if (paragraphs.find((p) => p.id === x.paragraphId).demandId) {
               return { ...(await UpdateDemand({ ...x, id: paragraphs.find((p) => p.id === x.paragraphId).demandId } as Demand)), paragraphId: x.paragraphId }
            } else {
               return { ...(await AddDemand(x as Demand)), paragraphId: x.paragraphId }
            }
         })
      )

      await Promise.all(
         addedParagraphDemands.map(async (x, i) => {
            const paragraph = paragraphs.find((p) => p.id === x.paragraphId)
            if (paragraph) {
               paragraph.demandId = x.id
               paragraph.law = law
               paragraph.status = true
               await UpdateParagraph(paragraph)
            }
         })
      )
      paragraphs = await deleteUnselectedParagraphDemands(paragraphs, SelectedParagraphs, law)

      setParagraphs(paragraphs)

      setSelectedLaw(law)
      reset({ LawDemand: demand, SelectedParagraphs: addedParagraphDemands })
      if (law.status === LawStatus.Demand) {
         AddItemsToCollection(dataContext.setRootState, dataContext.state.demands, [demand, ...addedParagraphDemands], 'demands')
      }
      handleMessage(setRootState, 'success', t('SavedSuccess'), law.status === LawStatus.Demand ? t('DemandsSent') : t('SavedDemands'))
   } catch (error) {
      handleMessage(setRootState, 'error', t('SavedError'), '')
   } finally {
      setIsSubmitting(false)
   }
}

export const clearDemands = async (
   dataContext: IDataContext,
   reset: UseFormReset<LawFormValues>,
   setSelectedLaw: React.Dispatch<React.SetStateAction<Law>>,
   setParagraphs: React.Dispatch<React.SetStateAction<Paragraph[]>>,
   t: TFunction<'translation', undefined>,
   paragraphs: Paragraph[],
   law: Law,
   user: AccountInfo,
   closeModal: () => void
) => {
   try {
      if (law.demandId) {
         const demandsIdsToDelete: string[] = [law.demandId, ...paragraphs.filter((x) => x.demandId).map((x) => x.demandId)]
         deleteDemands(demandsIdsToDelete)
      }
      law.demandId = null
      law.status = LawStatus.Empty
      law.changedBy = user.name
      law.modified = new Date().toISOString()
      law.paragraphs = []
      UpdateLaw(law)
      setSelectedLaw(law)
      await Promise.all(
         paragraphs.map((x) => {
            x.demandId = null
            x.law = law
            x.status = false
            UpdateParagraph(x)
            return x
         })
      )
      setParagraphs((prev) => prev.map((x) => ({ ...x, demandId: null })))
      reset({ LawDemand: getNewSecurityDemand(DemandSubType.Law), SelectedParagraphs: [] })
      handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), t('DemandSuccessfullyCleared'))
      closeModal()
   } catch (error) {
      handleMessage(dataContext.setRootState, 'error', t('SavedError'), t('DemandClearingFailed'))
   }
}

export const getAnswerOptionColumns = () => [
   createTableColumn<AnswerOption>({
      columnId: 'order',
      compare: (a, b) => {
         return a.order - b.order
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('Order')}</Text>
      },
      renderCell: (item) => {
         return <Text as="strong">{item.order}</Text>
      },
   }),
   createTableColumn<AnswerOption>({
      columnId: 'text',
      compare: (a, b) => {
         return a.text.localeCompare(b.text)
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('Title')}</Text>
      },
      renderCell: (item) => {
         return (
            <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/systemsettings/answeroptions/${item.id}`}>
               {item.text}
            </NavLink>
         )
      },
   }),
   createTableColumn<AnswerOption>({
      columnId: 'infoLabel',
      compare: (a, b) => {
         if (a.infoLabel === null && b.infoLabel === null) {
            return 0
         } else if (a.infoLabel === null) {
            return -1
         } else if (b.infoLabel === null) {
            return 1
         } else {
            return a.infoLabel.localeCompare(b.infoLabel)
         }
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('Info')}</Text>
      },
      renderCell: (item) => {
         return <Text>{item.infoLabel?.substring(0, 100)}</Text>
      },
   }),
   createTableColumn<AnswerOption>({
      columnId: 'value',
      compare: (a, b) => {
         return a.value - b.value
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('Value')}</Text>
      },
      renderCell: (item) => {
         return <Text>{item.value}</Text>
      },
   }),
   createTableColumn<AnswerOption>({
      columnId: 'answerType',
      compare: (a, b) => {
         return a.value.toString().localeCompare(b.value.toString())
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('AnswerType')}</Text>
      },
      renderCell: (item) => {
         return <Text>{enumValuesToTranslate(AnswerType, t, item.answerType)}</Text>
      },
   }),

   createTableColumn<AnswerOption>({
      columnId: 'modified',
      compare: (a, b) => {
         const dateA = new Date(a.modifiedDate ?? new Date())
         const dateB = new Date(b.modifiedDate ?? new Date())
         return dateA.getTime() - dateB.getTime()
      },
      renderHeaderCell: () => {
         return <Text as="strong">{t('Modified')}</Text>
      },
      renderCell: (item) => {
         return item.modifiedDate ? <Text>{dayjs(item.modifiedDate).format('YYYY-MM-DD')}</Text> : null
      },
   }),
]
