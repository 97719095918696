import React from 'react'
import { IExtendedColumn } from '../../interfaces/IColumn'
import { enumValuesToTranslate } from '../../helpers/enumHelper'
import { Demand, DemandArea, DemandType, Question, QuestionStatus } from '../../api/schemas/schema'
import { t } from 'i18next'
import { NavLink } from 'react-router-dom'
import StatusPill from '../../components/common/status/StatusPill'
import { statusBadgeColor } from '../../helpers/demandHelper'
import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components'

type ReorderQuestionRowProps = {
   columns: IExtendedColumn[]
   rowClass: string
   demand: Demand
   question: Question
}
const useStyles = makeStyles({
   customTableRow: {
      backgroundColor: tokens.colorPaletteMarigoldBackground1,
      '&:hover': {
         backgroundColor: tokens.colorPaletteMarigoldBackground2,
      },
   },
})

export const ReorderQuestionRow = ({ rowClass, columns, demand, question }: ReorderQuestionRowProps) => {
   const classes = useStyles()
   const questionFakeIdGenerator = (question: Question): string => {
      const type = enumValuesToTranslate(DemandType, t, demand.type, { lng: 'sv' })[0]
      const area = enumValuesToTranslate(DemandArea, t, demand.area, { lng: 'sv' })[0]
      return `Q${question.order}D${demand.level}${type}${area}${demand.order < 10 ? '0' + demand.order : demand.order}`
   }
   return (
      <div className={mergeClasses(rowClass, classes.customTableRow)}>
         <span style={{ width: columns[0].width, minWidth: columns[0].minWidth }} />
         <span style={{ width: columns[1].width, minWidth: columns[1].minWidth }} />
         <span style={{ width: columns[2].width, minWidth: columns[2].minWidth }}>{questionFakeIdGenerator(question)}</span>
         <span style={{ width: columns[3].width, minWidth: columns[3].minWidth }}>
            <NavLink style={{ textDecoration: 'none', color: 'black' }} to={`/demands/${demand.id}?question=${question.order}`}>
               {question.text.length > 100 ? question.text.substring(0, 100) : question.text}
            </NavLink>
         </span>
         <span style={{ width: columns[4].width, minWidth: columns[4].minWidth }} />
         <span style={{ width: columns[5].width, minWidth: columns[5].minWidth }} />
         <span style={{ width: columns[6].width, minWidth: columns[6].minWidth }} />
         <span style={{ width: columns[7].width, minWidth: columns[7].minWidth }} />
         <span style={{ width: columns[8].width, minWidth: columns[8].minWidth }} />
         <span style={{ width: columns[9].width, minWidth: columns[9].minWidth }}>
            <StatusPill color={statusBadgeColor(question.status)}>{enumValuesToTranslate(QuestionStatus, t, question.status)}</StatusPill>
         </span>
      </div>
   )
}
