import React, { useContext, useRef } from 'react'
import { Button, Field, Text, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { Add16Regular, Image20Regular, Dismiss16Regular, DocumentPdf20Regular, Document20Regular } from '@fluentui/react-icons'
import { useTranslation } from 'react-i18next'
import { UseFormReturn } from 'react-hook-form'
import { QuestionFormValues } from '../../../helpers/questionHelper'
import { Api } from '../../../api/schemas/schema'
import { Modal } from '../modal/Modal'
import { useBoolean } from '@fluentui/react-hooks'
import { DataContext } from '../../../App'
import { deleteAttachment, fileOnchange, getBlob } from '../../../helpers/fileHelper'

type FileInputProps = {
   label: string
   questionForm: UseFormReturn<QuestionFormValues>
   disabled: boolean
}
const useStyles = makeStyles({
   fileWrapper: {
      display: 'flex',
      alignItems: 'center',
      ...shorthands.gap(tokens.spacingHorizontalS),
   },
   contentStart: {
      justifyContent: 'start',
   },
   pointer: {
      cursor: 'pointer',
   },
})
export const FileInput = ({ label, questionForm, disabled }: FileInputProps) => {
   const inputFile = useRef(null)
   const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })
   const dataContext = useContext(DataContext)
   const [open, { setTrue: openDeleteAttachmentModal, setFalse: closeDeleteAttachmentModal }] = useBoolean(false)
   const classes = useStyles()
   const { t } = useTranslation()
   const { setValue, watch } = questionForm
   const getIcon = (name: string): JSX.Element => {
      const type = name.substring(name.lastIndexOf('.') + 1)
      switch (type) {
         case 'png':
         case 'jpg':
         case 'jpeg':
            return <Image20Regular />
         case 'pdf':
            return <DocumentPdf20Regular />
         default:
            return <Document20Regular />
      }
   }
   return (
      <Field label={label}>
         <input type="file" ref={inputFile} style={{ display: 'none' }} onChange={(e) => fileOnchange(e, questionForm)} disabled={disabled} />
         {watch('selectedQuestion.attachment') ? (
            <span className={classes.fileWrapper}>
               {getIcon(watch('selectedQuestion.attachment.name'))}
               <Text onClick={async () => getBlob(questionForm, apiInstance)} style={{ cursor: 'pointer' }}>
                  {watch('selectedQuestion.attachment.name')}
               </Text>
               {watch('selectedQuestion.attachment.id') ? (
                  <Dismiss16Regular onClick={openDeleteAttachmentModal} className={classes.pointer} color={tokens.colorPaletteRedForeground1} />
               ) : (
                  <Dismiss16Regular
                     onClick={() => {
                        setValue('selectedQuestion.attachment', null, { shouldDirty: true })
                        inputFile.current.value = ''
                     }}
                     className={classes.pointer}
                     color={tokens.colorPaletteRedForeground1}
                  />
               )}
            </span>
         ) : (
            <Button onClick={() => inputFile.current.click()} size="small" icon={<Add16Regular />} appearance="transparent" className={classes.contentStart}>
               {t('AddFile')}
            </Button>
         )}
         <Modal
            open={open}
            title={t('DeleteAttachment')}
            actions={
               <>
                  <Button
                     onClick={async () => deleteAttachment(questionForm, apiInstance, dataContext, closeDeleteAttachmentModal, t, inputFile)}
                     appearance="primary"
                     style={{ backgroundColor: tokens.colorStatusDangerForeground1 }}
                  >
                     {t('Delete')}
                  </Button>
                  <Button onClick={closeDeleteAttachmentModal}>{t('Cancel')}</Button>
               </>
            }
            children={t('DeleteAttachmentText')}
         />
      </Field>
   )
}
