import { TFunction } from 'i18next'
import { DeleteDemand } from '../api/demand'
import { UpdateParagraph } from '../api/paragraph'
import { Api, Demand, DemandArea, DemandStatus, DemandType, Law, Origin, Paragraph, Question } from '../api/schemas/schema'
import { enumValuesToTranslate } from './enumHelper'
import { IDataContext } from '../interfaces/IDataContext'
import { AddItemToCollection } from './stateHelper'
import { UseFormReset } from 'react-hook-form'
import { NavigateFunction } from 'react-router-dom'

const apiInstance = new Api({ baseUrl: process.env.REACT_APP_API_URL })

export const deleteUnselectedParagraphDemands = async (
   paragraphs: Paragraph[],
   SelectedParagraphs: (Demand & {
      paragraphId: number
   })[],
   law: Law
): Promise<Paragraph[]> => {
   try {
      if (
         JSON.stringify(
            paragraphs
               .filter((x) => x.demandId)
               .map((x) => x.demandId)
               .sort()
         ) !==
         JSON.stringify(
            SelectedParagraphs.filter((x) => x.id)
               .map((x) => x.id)
               .sort()
         )
      ) {
         const paragraphsToDelete = paragraphs.filter((paragraph) => {
            return paragraph.demandId && !SelectedParagraphs.some((selectedParagraph) => selectedParagraph.paragraphId === paragraph.id)
         })
         await Promise.all(
            paragraphsToDelete.map(async (paragraph) => {
               await DeleteDemand(paragraph.demandId)
               paragraphs.find((x) => x.id === paragraph.id).demandId = null
               paragraphs.find((x) => x.id === paragraph.id).law = law
               UpdateParagraph(paragraphs.find((x) => x.id === paragraph.id))
            })
         )
      }
      return paragraphs
   } catch (error) {}
}

export const deleteDemands = async (demandIds: string[]): Promise<void> => {
   await Promise.all(
      demandIds.map(async (id) => {
         await DeleteDemand(id)
      })
   )
}
export const statusBadgeColor = (lvl: number): 'brand' | 'success' | 'warning' | 'danger' => {
   switch (lvl) {
      case 0:
         return 'success'
      case 1:
         return 'warning'
      default:
         break
   }
}
export const fakeIdGenerator = (demand: Demand, t: TFunction<'translation', undefined>, parentDemand: Demand): string => {
   if (demand) {
      const type = enumValuesToTranslate(DemandType, t, demand?.type, { lng: 'sv' })[0]
      const area = enumValuesToTranslate(DemandArea, t, demand?.area, { lng: 'sv' })[0]

      return `D${demand.level}${type}${area}${demand.level < 3 ? '' : parentDemand?.order < 10 ? '0' + parentDemand?.order : parentDemand?.order}${
         demand.order < 10 ? '0' + demand.order : demand.order
      }`
   } else return null
}

export const getNewDemand = (parentDemand: Demand, demands: Demand[], modifiedBy: string): Demand => ({
   area: parentDemand?.area,
   level: parentDemand?.level + 1,
   modifiedBy: modifiedBy,
   order: demands.filter((x) => x.parentId === parentDemand.id).length + 1,
   origin: Origin.Sweden,
   parentId: parentDemand?.id,
   source: '',
   type: parentDemand?.type,
   subType: null,
   text: '',
   url: '',
   urlText: '',
})

export const updateDemand = async (
   demand: Demand,
   dataContext: IDataContext,
   t: TFunction<'translation', undefined>,
   reset: UseFormReset<Demand>,
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
   demands: Demand[]
) => {
   try {
      const updatedDemand: Demand = (await apiInstance.api.demandUpdate(demand)).data
      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), '')
      const updatedChilds = demands
         .filter((x) => x.parentId === updatedDemand.id)
         .map((child) => ({
            ...child,
            area: updatedDemand.area,
            type: updatedDemand.type,
            modifiedBy: updatedDemand.modifiedBy,
            modifiedDate: updatedDemand.modifiedDate,
         }))
      let updatedDemands: Demand[] = demands.map((d) => (d.id === demand.id ? updatedDemand : d))
      dataContext.setRootState((prev) => ({
         ...prev,
         demands: updatedDemands
            .map((d) => (d.parentId === updatedDemand.id ? updatedChilds.find((c) => c.id === d.id) || d : d))
            .sort((a, b) => a.order - b.order),
      }))

      reset(demand)
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   } finally {
      setIsSubmitting(false)
   }
}

export const deletableDemand = (dataContext: IDataContext, demand: Demand): boolean => {
   const { demands, questions } = dataContext.state

   return demands.some((x) => x.parentId === demand.id) || questions.some((x) => x.parentId === demand.id)
}

export const archiveDemand = async (
   demand: Demand,
   dataContext: IDataContext,
   t: TFunction<'translation', undefined>,
   reset: UseFormReset<Demand>,
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
   modifiedBy: string,
   setOpenModal: React.Dispatch<React.SetStateAction<'archive' | 'delete' | 'undo'>>
) => {
   setIsSubmitting(true)
   const { demands, questions } = dataContext.state
   try {
      demand.modifiedBy = modifiedBy
      if (demand.status === DemandStatus.Archived) {
         const activeSiblings = demands.filter((x) => x.parentId === demand.parentId && x.status !== DemandStatus.Archived)
         demand.status = DemandStatus.Ongoing
         demand.order = (activeSiblings.length > 0 ? activeSiblings.sort((a, b) => b.order - a.order)[0].order : 0) + 1
      } else {
         demand.status = DemandStatus.Archived
      } //Archive
      const updatedDemand: Demand = (await apiInstance.api.demandArchiveUpdate(demand)).data
      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), '')
      const updatedChilds = demands
         .filter((x) => x.parentId === updatedDemand.id)
         .map((child) => ({
            ...child,
            status: updatedDemand.status,
            modifiedBy: updatedDemand.modifiedBy,
            modifiedDate: updatedDemand.modifiedDate,
         }))
      const questionsToUpdate: Question[] = [
         ...questions
            .filter((x) => x.parentId === demand.id)
            .map((x) => ({
               ...x,
               modifiedBy: updatedDemand.modifiedBy,
            })),
         ...updatedChilds.flatMap((child) => {
            return questions
               .filter((x) => x.parentId === child.id)
               .map((x) => ({
                  ...x,
                  modifiedBy: updatedDemand.modifiedBy,
               }))
         }),
      ]
      let updatedQuestions: Question[] = (await apiInstance.api.questionArchiveUpdate(questionsToUpdate)).data

      let updatedDemandArray = await updateOrderOfDemands(demands, demand)
      updatedDemandArray = updatedDemandArray.map((x) => (x.id === updatedDemand.id ? updatedDemand : x))
      dataContext.setRootState((prev) => ({
         ...prev,
         demands: updatedDemandArray
            .map((d) => (d.parentId === updatedDemand.id ? updatedChilds.find((c) => c.id === d.id) || d : d))
            .sort((a, b) => a.order - b.order),
         questions: prev.questions.map((x) => updatedQuestions.find((q) => q.id === x.id) ?? x).sort((a, b) => a.order - b.order),
      }))
      reset(demand)
      setOpenModal(null)
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   } finally {
      setIsSubmitting(false)
   }
}

export const deleteDemandAndRestLaw = async (
   demand: Demand,
   dataContext: IDataContext,
   t: TFunction<'translation', undefined>,
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
   setOpenModal: React.Dispatch<React.SetStateAction<'archive' | 'delete' | 'undo'>>,
   modifiedBy: string,
   navigate: NavigateFunction
) => {
   setIsSubmitting(true)
   try {
      const { demands } = dataContext.state
      await apiInstance.api.demandResetlawDelete(demand.id, modifiedBy)
      const updatedDemandArray = await updateOrderOfDemands(demands, demand)
      dataContext.handleMessage(dataContext.setRootState, 'success', t('ItemDeleted'), '')
      dataContext.setRootState((prev) => ({
         ...prev,
         demands: updatedDemandArray.filter((l) => l.id !== demand.id).sort((a, b) => a.order - b.order),
      }))
      setOpenModal(null)
      navigate('/demands')
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('DeleteError'), error.message)
   } finally {
      setIsSubmitting(false)
   }
}

export const addDemand = async (
   demand: Demand,
   dataContext: IDataContext,
   t: TFunction<'translation', undefined>,
   setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>,
   navigate: NavigateFunction
) => {
   const { demands } = dataContext.state
   try {
      const newDemand: any = await apiInstance.api.demandCreate(demand)
      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), '')
      AddItemToCollection(dataContext.setRootState, demands, newDemand.data, 'demands')
      navigate(`/demands/${newDemand.data.id}`)
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   } finally {
      setIsSubmitting(false)
   }
}

export const addDemandFromList = async (
   demand: Demand,
   updatedDemands: Demand[],
   dataContext: IDataContext,
   t: TFunction<'translation', undefined>,
   navigate: NavigateFunction
) => {
   const { demands } = dataContext.state
   try {
      const newDemands = [...demands]
      const newDemand: any = await apiInstance.api.demandCreate(demand)
      newDemands.push(newDemand.data)
      await apiInstance.api.demandBatchUpdate(updatedDemands)

      const updatedNewDemands = newDemands.map((existingDemand) => {
         const updatedDemand = updatedDemands.find((updatedDemand) => updatedDemand.id === existingDemand.id)
         return updatedDemand ? updatedDemand : existingDemand
      })

      dataContext.setRootState({
         ...dataContext.state,
         demands: updatedNewDemands.sort((a, b) => a.order - b.order),
      })
      dataContext.handleMessage(dataContext.setRootState, 'success', t('SavedSuccess'), '')

      navigate(`/demands/${newDemand.data.id}`)
   } catch (error: any) {
      dataContext.handleMessage(dataContext.setRootState, 'error', t('SavedError'), error.message)
   }
}

export const updateOrderOfDemands = async (demands: Demand[], demand: Demand) => {
   const demandsToUpdateOrder: Demand[] = []
   demands
      .filter((x) => x.parentId === demand.parentId && x.id !== demand.id)
      .forEach((x, i) => {
         if (x.order !== i + 1) {
            demandsToUpdateOrder.push({
               ...x,
               order: i + 1,
            })
         }
      })

   const updated = (await apiInstance.api.demandBatchUpdate(demandsToUpdateOrder)).data
   return [...demands].map((x) => (updated.find((u) => u.id === x.id) ? updated.find((u) => u.id === x.id) : x))
}
