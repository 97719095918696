import React from 'react'
import { useTranslation } from 'react-i18next'
import { Page } from './Page'
import { Title } from '../components/common/texts/Title'

export const Dashboard = () => {
   const { t } = useTranslation()

   return <Page header={<Title as="h2">{t('Dashboard')}</Title>}>.</Page>
}
