import React, { useEffect } from 'react'
import { PublicClientApplication } from '@azure/msal-browser'
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from '@azure/msal-react'
import { Login } from './pages/Login'
import { setupAxios } from './api/setupAxios'
import { msalConfig } from './auth/authConfig'
import App from './App'

export const msalInstance = new PublicClientApplication(msalConfig)

export const Root = () => {
   useEffect(() => {
      const init = async () => {
         await setupAxios(msalInstance)
      }
      init()
   }, [])
   useEffect(() => {
      if (window.location.pathname !== '/' && msalInstance.getAllAccounts().length === 0) {
         window.location.replace('/')
      }
   }, [])

   return (
      <MsalProvider instance={msalInstance}>
         <AuthenticatedTemplate>
            <App />
         </AuthenticatedTemplate>
         <UnauthenticatedTemplate>
            <Login />
         </UnauthenticatedTemplate>
      </MsalProvider>
   )
}
