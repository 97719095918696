import SiteConstants from '../assets/SiteConstants'
import { Paragraph } from './schemas/schema'
import axios from 'axios'

export const GetParagraphsByLawId = async (lawId: string): Promise<Paragraph[]> => {
   try {
      const response = await axios.get(`/${SiteConstants.PARAGRAPH}/${lawId}`)
      const paragraphs: Paragraph[] = response.data
      return paragraphs
   } catch (error) {
      return []
   }
}
export const UpdateParagraph = async (updatedParagraph: Paragraph): Promise<void> => {
   try {
      await axios.put(`/${SiteConstants.PARAGRAPH}`, updatedParagraph, {
         headers: {
            'Content-Type': 'application/json',
         },
      })
   } catch (error) {
      throw error
   }
}
