import React from 'react'
import { Subtitle1, Subtitle2, Title3, makeStyles, mergeClasses, tokens } from '@fluentui/react-components'

const useStyles = makeStyles({
   specialHeader: { lineHeight: tokens.lineHeightHero800, fontSize: tokens.fontSizeBase500 },
})
type TitleProps = {
   as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5'
   children: string | string[]
   className?: string
}
export const Title = ({ as, children, className }: TitleProps): JSX.Element => {
   const classes = useStyles()
   switch (as) {
      case 'h1':
         return (
            <Title3 className={className ?? ''} as={as}>
               {children}
            </Title3>
         )
      case 'h2':
         return (
            <Subtitle1 className={mergeClasses(classes.specialHeader, className)} as={as}>
               {children}
            </Subtitle1>
         )
      case 'h3':
         return (
            <Subtitle1 className={className ?? ''} as={as}>
               {children}
            </Subtitle1>
         )
      case 'h4':
         return (
            <Subtitle2 as={as} className={className ?? ''}>
               {children}
            </Subtitle2>
         )
      case 'h5':
      default:
         return (
            <Subtitle2 as={as} className={className ?? ''}>
               {children}
            </Subtitle2>
         )
   }
}
