import { Table, TableHeader, TableRow, TableHeaderCell, TableBody, tokens } from '@fluentui/react-components'
import React, { useContext } from 'react'
import { DataContext } from '../../App'
import { questionColumns } from '../../helpers/columnHelper'
import { useTranslation } from 'react-i18next'
import { QuestionStatus } from '../../api/schemas/schema'
import { QuestionRow } from './QuestionRow'

export const QuestionTable = () => {
   const dataContext = useContext(DataContext)
   const { questions, demands } = dataContext.state
   const { t } = useTranslation()

   return (
      <Table aria-label="Demand table" as="table">
         <TableHeader as="thead">
            <TableRow as="tr">
               {questionColumns(t).map((column) => (
                  <TableHeaderCell as="th" style={{ width: column.width, fontWeight: tokens.fontWeightBold }} key={column.columnId}>
                     {column.label}
                  </TableHeaderCell>
               ))}
            </TableRow>
         </TableHeader>
         <TableBody as="tbody">
            {questions
               .filter((q) => q.status !== QuestionStatus.Archived)
               .map((question) => {
                  return <QuestionRow question={question} demand={demands.find((d) => d.id === question.parentId)} />
               })}
         </TableBody>
      </Table>
   )
}
