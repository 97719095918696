import { Spinner, makeStyles, mergeClasses } from '@fluentui/react-components'
import React from 'react'
const useStyles = makeStyles({
   spinner: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 100,
   },
   opacity: { backgroundColor: 'white', opacity: 0.4 },
})
type LoaderProps = {
   opacity: boolean
}
export const Loader = ({ opacity }: LoaderProps): JSX.Element => {
   const classes = useStyles()
   return <Spinner className={mergeClasses(classes.spinner, opacity ? classes.opacity : '')} />
}
