import { Badge, makeStyles, shorthands } from '@fluentui/react-components'
import React from 'react'

type StatusPillProps = {
   children: string | number
   color?: 'brand' | 'danger' | 'warning' | 'success'
}
const useStyles = makeStyles({
   success: {
      ...shorthands.borderColor('#BAD80A'),
      backgroundColor: '#DFF6DD',
   },
   warning: {
      ...shorthands.borderColor('#FFB900'),
      backgroundColor: '#FFF4CE',
   },
   danger: {
      ...shorthands.borderColor('#910E00'),
      backgroundColor: '#FDE7E9',
   },
})
const StatusPill = ({ children, color }: StatusPillProps) => {
   const classes = useStyles()
   return (
      <Badge
         appearance="outline"
         className={color === 'success' ? classes.success : color === 'danger' ? classes.danger : color === 'warning' ? classes.warning : ''}
         color={!color ? 'brand' : color}
      >
         {children}
      </Badge>
   )
}

export default StatusPill
