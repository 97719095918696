import { Button, SelectTabData, SelectTabEvent, Tab, TabList, makeStyles } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { Page } from '../Page'
import { Title } from '../../components/common/texts/Title'
import { useContext, useState } from 'react'
import { CustomDemandTable } from './CustomDemandTable'
import { DataContext } from '../../App'
import { QuestionTable } from '../Question/QuestionTable'
import { excelExporter } from '../../helpers/excelHelper'
const useStyles = makeStyles({
   spaceBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
   },
})
export const ComplianceOfDemands = () => {
   const classes = useStyles()
   const { t } = useTranslation()
   const dataContext = useContext(DataContext)
   const { selectedTab } = dataContext.state

   const [exportLoading, setExportLoading] = useState<boolean>(false)
   const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
      dataContext.setRootState((prev) => ({ ...prev, selectedTab: data.value.toString() }))
   }
   return (
      <Page
         header={<Title as="h2">{t('DemandQuestionList')}</Title>}
         subheader={
            <div className={classes.spaceBetween}>
               <TabList selectedValue={selectedTab} onTabSelect={onTabSelect}>
                  <Tab value="activeDemands">{t('ActiveDemands')}</Tab>
                  <Tab value="activeQuestions">{t('ActiveQuestions')}</Tab>
                  <Tab value="archivedDemands">{t('ArchivedDemands')}</Tab>
               </TabList>
               <Button disabled={exportLoading} onClick={async () => await excelExporter(dataContext, t, setExportLoading)}>
                  {t('ExportDemandsAndQuestionsToExcel')}
               </Button>
            </div>
         }
      >
         {selectedTab === 'activeQuestions' ? <QuestionTable /> : <CustomDemandTable />}
      </Page>
   )
}
