import { Avatar, Text, tokens, shorthands, makeStyles } from '@fluentui/react-components'
import React from 'react'

type AvatarNameProps = {
   name: string
}

const useStyles = makeStyles({
   avatarWrapper: {
      display: 'flex',
      ...shorthands.gap(tokens.spacingHorizontalXS),
      alignItems: 'center',
   },
})
export const AvatarName = ({ name }: AvatarNameProps) => {
   const classes = useStyles()
   return (
      <span className={classes.avatarWrapper}>
         <Avatar color={'colorful'} size={24} name={name} />
         <Text>{name}</Text>
      </span>
   )
}
