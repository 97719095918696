import { Avatar, Field, Input, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import React from 'react'
const useStyles = makeStyles({
   fwSemiBold: {
      fontWeight: tokens.fontWeightSemibold,
   },
   noBorder: { ...shorthands.border('none') },
})
type TextOutputProps = {
   label: string
   value: string
   avatar?: boolean
}
const TextOutput = ({ label, value, avatar }: TextOutputProps) => {
   const classes = useStyles()
   return (
      <Field className={classes.fwSemiBold} label={label}>
         <Input
            contentBefore={avatar ? <Avatar color={'colorful'} size={24} name={value ?? ''} /> : null}
            className={classes.noBorder}
            value={value ?? ''}
            disabled={true}
         />
      </Field>
   )
}

export default TextOutput
