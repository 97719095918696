import React from 'react'
import { Text } from '@fluentui/react-components'

type ArrowProps = {
   direction: 'left' | 'right'
   className: string
}
export const Arrow = ({ direction, className }: ArrowProps) => {
   return <Text className={className}>{direction === 'left' ? '<' : '>'}</Text>
}
