import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrandVariants, FluentProvider, Theme, createLightTheme } from '@fluentui/react-components'
import './i18n/i18n'
import { Root } from './Root'
import './index.css'

const Robust: BrandVariants = {
   10: '#010305',
   20: '#0B1A20',
   30: '#102A34',
   40: '#173643',
   50: '#234351',
   60: '#304F5E',
   70: '#3D5C6B',
   80: '#4C6A78',
   90: '#5A7785',
   100: '#6A8592',
   110: '#7993A0',
   120: '#8AA1AD',
   130: '#9AAFBA',
   140: '#ACBEC7',
   150: '#BECCD4',
   160: '#D0DBE0',
}

const lightTheme: Theme = {
   ...createLightTheme(Robust),
   fontFamilyBase: 'Segoe UI',
   colorPaletteGoldBackground2: '#E9DFBC',
   colorPaletteMarigoldBackground1: '#FBF9F3',
   colorPaletteMarigoldBackground2: '#F8F4E8',
   colorBrandBackground: '#263B52',
}
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
   <React.StrictMode>
      <FluentProvider theme={lightTheme}>
         <Root />
      </FluentProvider>
   </React.StrictMode>
)
