import React, { useContext, useEffect, useState } from 'react'
import { Page } from './Page'
import { DataContext } from '../App'
import { Button, Divider, InfoLabel, MessageBar, MessageBarBody, MessageBarTitle, makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Loading } from './Loading'
import { Grid } from '../components/common/grid/Grid'
import { GridItem } from '../components/common/grid/GridItem'
import { Title } from '../components/common/texts/Title'
import { GetParagraphsByLawId } from '../api/paragraph'
import { GetLawById } from '../api/law'
import { setLoading } from '../helpers/stateHelper'
import { Demand, DemandSubType, Law, LawStatus, Paragraph } from '../api/schemas/schema'
import { ParagraphComponent } from '../components/paragraph/Paragraph'
import { useFieldArray, useForm } from 'react-hook-form'
import { clearDemands, getNewSecurityDemand, saveLawAndParagraphDemands } from '../helpers/lawHelper'
import { LawControls } from '../components/law/LawControls'
import { useMsal } from '@azure/msal-react'
import { GetDemandById } from '../api/demand'
import SelectedLawHeader from '../components/law/SelectedLawHeader'
import { useBoolean } from '@fluentui/react-hooks'
import { Modal } from '../components/common/modal/Modal'

const useStyles = makeStyles({
   paddingL: {
      ...shorthands.padding(tokens.spacingVerticalXL, tokens.spacingHorizontalXL),
   },
})
export type LawFormValues = {
   LawDemand: Demand
   SelectedParagraphs: Array<Demand & { paragraphId: number }>
}
export const SelectedLaw = () => {
   const { t } = useTranslation()
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const { isLoading } = dataContext.state
   const { setRootState } = dataContext
   const [selectedLaw, setSelectedLaw] = useState<Law>(null)
   const [paragraphs, setParagraphs] = useState<Paragraph[]>([])
   const { accounts } = useMsal()
   const demandForm = useForm<LawFormValues>({
      defaultValues: {
         LawDemand: getNewSecurityDemand(DemandSubType.Law),
         SelectedParagraphs: [],
      },
   })
   const { control, watch, reset } = demandForm
   const selectedParagraphsFieldArray = useFieldArray({
      control,
      name: 'SelectedParagraphs',
   })
   const isDisabled =
      selectedLaw && (selectedLaw.status === LawStatus.Demand || selectedLaw.status === LawStatus.No || selectedLaw.status === LawStatus.Archived)
   const { id } = useParams()
   const [clearDemandModalOpen, { setTrue: openClearDemandModal, setFalse: closeClearDemandModal }] = useBoolean(false)
   const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
   useEffect(() => {
      ;(async () => {
         setLoading(setRootState, true)
         try {
            const lawResult = await GetLawById(id)
            const paragraphsResult = await GetParagraphsByLawId(id)
            setParagraphs(paragraphsResult)
            const paragraphDemands: Array<Demand & { paragraphId: number }> = await Promise.all(
               paragraphsResult
                  .filter((x) => x.demandId)
                  .map(async (x) => ({
                     ...(await GetDemandById(x.demandId)),
                     paragraphId: x.id,
                  }))
            )
            if (lawResult.demandId) {
               const lawDemand = await GetDemandById(lawResult.demandId)
               reset({ LawDemand: lawDemand, SelectedParagraphs: paragraphDemands })
            } else {
               reset((prev) => ({
                  ...prev,
                  SelectedParagraphs: paragraphDemands,
               }))
            }
            setSelectedLaw(lawResult)
         } catch (error) {
         } finally {
            setLoading(setRootState, false)
         }
      })()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [id])
   const onSubmit = (law: Law) => {
      setIsSubmitting(true)
      saveLawAndParagraphDemands(dataContext, reset, setSelectedLaw, setParagraphs, t, watch(), paragraphs, law, accounts[0], setIsSubmitting)
   }
   return (
      <>
         {isLoading || !selectedLaw ? (
            <Loading />
         ) : (
            <Page
               header={
                  <SelectedLawHeader
                     isSubmitting={isSubmitting}
                     demandForm={demandForm}
                     selectedLaw={selectedLaw}
                     isDisabled={isDisabled}
                     onSubmit={onSubmit}
                     openClearDemandModal={openClearDemandModal}
                  />
               }
            >
               {selectedLaw.status === LawStatus.No && (
                  <MessageBar>
                     <MessageBarBody>
                        <MessageBarTitle>{t('LawWithStatusNoCantBeHandled')}</MessageBarTitle>
                        {t('ChangeStatusToHandleLaw')}
                     </MessageBarBody>
                  </MessageBar>
               )}

               <Grid className={classes.paddingL}>
                  <LawControls demandForm={demandForm} selectedLaw={selectedLaw} isDisabled={isDisabled} />
                  <GridItem size="12/12">
                     <div>
                        <Title as="h4">{`${t('Content')}: `}</Title>
                        <InfoLabel info={t('ChooseParagraphsInfo')} />
                     </div>
                     <Divider />
                     <>
                        {paragraphs.map((p, i) => (
                           <ParagraphComponent
                              key={i}
                              item={p}
                              control={control}
                              selected={watch('SelectedParagraphs').some((f) => f.paragraphId === p.id)}
                              selectedParagraphsFieldArray={selectedParagraphsFieldArray}
                              isDisabled={isDisabled}
                           />
                        ))}
                     </>
                  </GridItem>
               </Grid>
               <Modal
                  open={clearDemandModalOpen}
                  title={t('ClearDemand')}
                  actions={
                     <>
                        <Button onClick={closeClearDemandModal}>{t('Cancel')}</Button>
                        <Button
                           onClick={() =>
                              clearDemands(dataContext, reset, setSelectedLaw, setParagraphs, t, paragraphs, selectedLaw, accounts[0], closeClearDemandModal)
                           }
                           appearance="primary"
                        >
                           {t('Clear')}
                        </Button>
                     </>
                  }
                  children={t('ClearDemandText')}
               />
            </Page>
         )}
      </>
   )
}
