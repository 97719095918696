import { Reorder } from 'framer-motion'
import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../App'
import { Demand, DemandStatus } from '../../api/schemas/schema'
import { ReorderRowContent } from './ReorderDemandRowContent'
import { makeStyles, shorthands, tokens } from '@fluentui/react-components'
import { useTranslation } from 'react-i18next'
import { demandColumns } from '../../helpers/columnHelper'
import { expandDemands, handleDrop, toggleRowExpansion } from '../../helpers/dndHelper'

const useStyles = makeStyles({
   row: {
      display: 'flex',
      ...shorthands.borderBottom(tokens.strokeWidthThin, 'solid', tokens.colorNeutralStroke2),
      ...shorthands.padding(tokens.spacingVerticalS, 0),
      '&:hover': {
         backgroundColor: tokens.colorNeutralBackground1Hover,
      },
      '&:active': {
         backgroundColor: tokens.colorNeutralBackground1Pressed,
      },
   },
   colheader: {
      fontWeight: tokens.fontWeightBold,
   },
})

export const CustomDemandTable = () => {
   const classes = useStyles()
   const dataContext = useContext(DataContext)
   const [showButton, setShowButton] = useState<string>('')
   const { demands, selectedTab, expandedRows } = dataContext.state
   const [items, setItems] = useState<Demand[]>([])
   const { t } = useTranslation()

   const handleDemandExpansion = (rowKey: string) => {
      toggleRowExpansion(dataContext, setItems, items, rowKey)
   }

   useEffect(() => {
      switch (selectedTab) {
         case 'activeDemands':
            setItems(
               expandDemands(
                  dataContext,
                  demands.filter((x) => x.parentId === '00000000-0000-0000-0000-000000000000')
               )
            )
            break
         case 'archivedDemands':
            setItems(
               expandDemands(
                  dataContext,
                  demands.filter(
                     (demand) => demand.status === DemandStatus.Archived && demands.find((x) => x.id === demand.parentId).status !== DemandStatus.Archived
                  )
               )
            )
            break

         default:
            break
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedTab])
   return (
      <div role="table">
         <div role="rowgroup">
            <div role="row" className={classes.row}>
               {demandColumns(t).map((col) => (
                  <span key={col.columnId} role="columnheader" className={classes.colheader} style={{ width: col.width, minWidth: col.minWidth }}>
                     {col.label}
                  </span>
               ))}
            </div>
         </div>
         <Reorder.Group layoutScroll as="div" role="rowgroup" axis="y" values={items} onReorder={setItems}>
            {items.map((item) => {
               return (
                  <Reorder.Item
                     onMouseEnter={() => setShowButton(item.id)}
                     onDragStart={() => {
                        if (expandedRows[item.id]) {
                           handleDemandExpansion(item.id)
                           items.filter((x) => x.parentId === item.id).map((x) => (expandedRows[x.id] ? handleDemandExpansion(x.id) : null))
                        }
                     }}
                     onDragEnd={() => handleDrop(t, dataContext, setItems, items, item)}
                     as="div"
                     role="row"
                     key={item.id}
                     value={item}
                  >
                     <ReorderRowContent
                        toggleRowExpansion={handleDemandExpansion}
                        item={item}
                        expanded={expandedRows[item.id] ?? false}
                        columns={demandColumns(t)}
                        rowClass={classes.row}
                        setShowButton={setShowButton}
                        showButton={showButton}
                     />
                  </Reorder.Item>
               )
            })}
         </Reorder.Group>
      </div>
   )
}
