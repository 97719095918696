import React from 'react'
import { makeStyles, Spinner } from '@fluentui/react-components'
import { Page } from './Page'

const useStyles = makeStyles({
   spinner: {
      position: 'absolute',
      top: '30%',
      right: 0,
      left: 0,
   },
})
export const Loading = () => {
   const classes = useStyles()

   return (
      <Page>
         <Spinner size="large" className={classes.spinner} />
      </Page>
   )
}
