import axios from 'axios'
import { PublicClientApplication } from '@azure/msal-browser'

export const setupAxios = async (msalInstance: PublicClientApplication) => {
   axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`
   axios.interceptors.request.use(
      async (config) => {
         const account = msalInstance.getAllAccounts()[0]
         const msalResponse = await msalInstance.acquireTokenSilent({
            scopes: ['offline_access', 'openid', 'profile', 'api://739ad14b-969e-49af-9d6f-6f68a6edb67b/api.scope'],
            account: account,
         })
         config.headers.Authorization = `Bearer ${msalResponse.accessToken}`

         return config
      },
      (err) => {
         return Promise.reject(err)
      }
   )
}
