import axios from 'axios'
import SiteConstants from '../assets/SiteConstants'
import { Question } from './schemas/schema'

export const GetQuestions = async (): Promise<Question[]> => {
   try {
      let url = `/${SiteConstants.QUESTIONS}?`
      const response = await axios.get(url)
      const questions: Question[] = response.data
      return questions.sort((a, b) => a.order - b.order)
   } catch (error) {
      throw error
   }
}

export const UpdateQuestion = async (data: Question): Promise<Question> => {
   try {
      let url = `/${SiteConstants.QUESTIONS}`
      const response = await axios.put(url, data)
      const question: Question = response.data
      return question
   } catch (error) {
      throw error
   }
}
export const AddQuestion = async (data: Question): Promise<Question> => {
   try {
      let url = `/${SiteConstants.QUESTIONS}`
      const response = await axios.post(url, data)
      const question: Question = response.data
      return question
   } catch (error) {
      throw error
   }
}
export const DeleteQuestion = async (id: string): Promise<void> => {
   try {
      await axios.delete(`/${SiteConstants.QUESTIONS}/${id}`)
   } catch (error) {
      throw error
   }
}
